import EventEmitter from 'wolfy87-eventemitter';

import ViewManager from '../../../managers/view';
import { desktopBreakpoint } from '../../../constants/breakpoints';
import { closest } from '../../../util/dom';

export default class Navigation extends EventEmitter {
  static previouslySelectedNavigationItem = null;

  static OPEN_EVENT = 'openEvent';

  static CLOSE_EVENT = 'closeEvent';

  constructor(element) {
    super();
    this.element = element;

    this.initNavigation();
  }


  initNavigation() {
    const subMenuButtons = [...this.element.querySelectorAll('[data-navigation*="sub-menu-button"]')];

    subMenuButtons.forEach((subMenuButton) => {
      const navigationItem = closest(subMenuButton, 'Navigation_item');
      subMenuButton.addEventListener('click', (event) => {
        event.stopPropagation();
        const isActive = Navigation.previouslySelectedNavigationItem !== navigationItem;

        if (isActive) {
          this.emit(Navigation.OPEN_EVENT);
        } else {
          this.emit(Navigation.CLOSE_EVENT);
        }

        this.deativatePreviouslySelectedNavigationItem();
        this.changeStateSubMenu(navigationItem, isActive);
      });
    });

    const subMenuBackButtons = [...this.element.querySelectorAll('[data-navigation*="sub-menu-back-button"]')];

    subMenuBackButtons.forEach((subMenuBackButton) => {
      subMenuBackButton.addEventListener('click', () => {
        this.deativatePreviouslySelectedNavigationItem();
      });
    });

    ViewManager.on(ViewManager.EVENT_UPDATE, this.onViewUpdate);
  }


  changeStateSubMenu(itemElement, isActive) {
    const rootElement = document.querySelector('main');
    if (isActive) {
      itemElement.classList.add('active');
      this.element.classList.add('active');
      if (window.innerWidth < desktopBreakpoint) {
        this.element.style.overflowY = 'visible';
        this.element.style.overflowX = 'visible';
      }

      Navigation.previouslySelectedNavigationItem = itemElement;
      rootElement.addEventListener('click', this.onRootElementClick);
    } else {
      itemElement.classList.remove('active');
      this.element.classList.remove('active');

      if (window.innerWidth < desktopBreakpoint) {
        const mobileMenuTransitionTime = 200; // milliseconds
        setTimeout(() => {
          // delay overflowY change until sub menu has animated shut
          this.element.style.overflowY = 'scroll';
          this.element.style.overflowX = 'hidden';
        }, mobileMenuTransitionTime);
      }
      Navigation.previouslySelectedNavigationItem = null;
      rootElement.removeEventListener('click', this.onRootElementClick);
    }
  }


  deativatePreviouslySelectedNavigationItem = () => {
    if (Navigation.previouslySelectedNavigationItem) {
      const isActive = false;
      this.changeStateSubMenu(Navigation.previouslySelectedNavigationItem, isActive);
    }
  }


  onRootElementClick = () => {
    this.emit(Navigation.CLOSE_EVENT);
    this.deativatePreviouslySelectedNavigationItem();
  }


  /**
   * Specific handling to set height and top for Navigation because of
   * potential variable height of header
   */
  onViewUpdate = ({ headerHeight, resizeUpdate }) => {
    // reset nav top and height when we transition above desktop
    if (ViewManager.hasTransitionedAboveAndWithin(desktopBreakpoint)) {
      this.element.style.top = '';
      this.element.style.height = '';
      if (this.element.style.overflowY === 'scroll') {
        this.element.style.overflowY = 'visible'; // reset overflow
      }
    }

    // continuously update below upon resize
    if (ViewManager.isBelow(desktopBreakpoint) && resizeUpdate) {
      this.element.style.top = `${headerHeight}px`;
      this.element.style.height = `calc(100% - ${headerHeight}px)`;
    }
  }
}
