import InViewManager from '../../managers/in-view';

export default class SeasonalPricingLoader {
  constructor(element) {
    if (!document.body.classList.contains('ExperienceEdit')) {
      InViewManager.add(element, this.onInView);
    }
  }

  onInView = (element) => {
    SeasonalPricingLoader.load(element);
  }

  static async load(element) {
    const { default: SeasonalPricing } = await import(/* webpackChunkName: "SeasonalPricing" */'../modules/SeasonalPricing');

    new SeasonalPricing(element);
  }
}
