const checkBody = (body) => {
  const illegalValues = [undefined, null, NaN, ''];
  const missing = Object.values(body).map(item => illegalValues.includes(item));
  return missing.some(err => err);
};

const getData = (endpoint, queryParams, durationRules, seasonRules, setLoading) => {
  // Clean up params
  const body = {
    campsiteItemId: queryParams.siteId,
    duration: parseInt(queryParams.duration, 10),
    startDate: queryParams.startDate,
    durationRules: JSON.parse(durationRules),
    seasonDates: JSON.parse(seasonRules),
  };
  const isMissingData = checkBody(body);
  if (isMissingData) return { error: 'Missing data' };
  // Get data
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      const parsedResponse = await res.json();
      if (res.status !== 200) {
        throw new Error(parsedResponse.Message).toString().replace('Error:', '');
      }
      return parsedResponse;
    })
    .catch(err => ({ error: err }))
    .finally(() => setLoading(false));
};

export default getData;
