import InViewManager from '../../managers/in-view';

export default class CarouselGridLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);
  }

  onInView = (element) => {
    if (!document.body.classList.contains('ExperienceEdit')) {
      CarouselGridLoader.loadFlicity(element);
    }
  }

  static async loadFlicity(element) {
    const { default: CarouselGrid } = await import(/* webpackChunkName: "CarouselGrid" */'../modules/CarouselGrid');

    new CarouselGrid(element);
  }
}
