export default class Utils {
  static sum(arr) {
    let sum = 0;
    let d = arr.length;
    while (d--) {
      sum += arr[d];
    }
    return sum;
  }

  static pad(n, width, z) {
    z = z || '0';
    n += '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  static toPrice(num, currency = '£') {
    return `${currency}${Number.parseFloat(num).toFixed(2)}`;
  }

  static hasLength(array) {
    if (array && array.constructor === Array) {
      if (array.length > 0) {
        return true;
      }
    }

    return false;
  }

  static updateQueryStringParameter(uri, key, value) {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, `$1${key}=${value}$2`);
    }

    return `${uri}${separator}${key}=${value}`;
  }

  static isFastConnection() {
    const { connection, mozConnection, webkitConnection } = window.navigator;
    const connectionObj = connection || mozConnection || webkitConnection;

    // Test connection speed is 4g or greater
    return connectionObj ? connectionObj.downlink > 2 : true;
  }

  static camelCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  static camelToKebab(string) {
    return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
  }

  static hashString(str) {
    let hash = 0;
    let i;
    let chr;
    for (i = 0; i < str.length; i += 1) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr; // eslint-disable-line no-bitwise
      hash &= hash; // eslint-disable-line no-bitwise
    }
    return hash;
  }
}
