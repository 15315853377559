export function pageY(elem, checkTransforms) {
  let translateY = 0;
  if (checkTransforms) {
    let transform;
    if (elem.offsetParent) {
      transform = window.getComputedStyle(elem.offsetParent).transform;
    } else {
      transform = window.getComputedStyle(elem).transform;
    }

    if (transform !== 'none' && transform !== '') {
      translateY = Number(transform.match(/\((.*?)\)/)[1].split(',')[5]);
    }
  }

  if (elem.offsetParent) {
    return elem.offsetTop + translateY + pageY(elem.offsetParent, checkTransforms);
  }
  return elem.offsetTop + translateY;
}

export function getOffsetTop(element) {
  let offsetTop = 0;

  do {
    let fixedFromTopOfBody = 0;
    if (!element.offsetParent) {
      const isFixed = window.getComputedStyle(element).position === 'fixed';
      if (isFixed) {
        const scrollingElememt = document.scrollingElement
          ? document.scrollingElement
          : document.documentElement;
        fixedFromTopOfBody = scrollingElememt.scrollTop;
      }
    }

    if (Number.isNaN(element.offsetTop) === false) {
      offsetTop += element.offsetTop + fixedFromTopOfBody;
    }
    element = element.offsetParent;
  } while (element);

  return offsetTop;
}

export function getOffsetLeft(element) {
  let offsetLeft = 0;

  do {
    if (Number.isNaN(element.offsetLeft) === false) {
      offsetLeft += element.offsetLeft;
    }
    element = element.offsetParent;
  } while (element);

  return offsetLeft;
}

export function pageX(element, checkTransforms) {
  let translateX = 0;

  if (checkTransforms) {
    let transform;

    if (element.offsetParent) {
      transform = window.getComputedStyle(element.offsetParent).transform;
    } else {
      transform = window.getComputedStyle(element).transform;
    }

    if (transform !== 'none' && transform !== '') {
      translateX = Number(transform.match(/\((.*?)\)/)[1].split(',')[4]);
    }
  }

  if (element.offsetParent) {
    return element.offsetLeft + translateX + pageX(element.offsetParent, checkTransforms);
  }
  return element.offsetLeft + translateX;
}
