import React from 'react';
import PropTypes from 'prop-types';
import CampsiteCard from '../../Regions/components/CampsiteCard';
import DateGrid from './DateGrid';

const AdditionalCampsites = ({ model, campsiteData, facets }) => (
  <>
    <h4 className="Campsites_title-alt">{model.relatedCampsitesTitle}</h4>
    {campsiteData.map(site => (
      <div key={site.id} className="Campsites_site Campsites_site-alt">
        <div className="Campsites_left">
          <div className="Campsites_card">
            <CampsiteCard
              layoutType="regions"
              campsiteData={site}
              labels={model}
              showSubmit={false}
              showReviews={false}
              isClickable={false}
              facetTypes={facets}
            />
          </div>
        </div>
        <div className="Campsites_right">
          <DateGrid className="DateGrid-alt" dates={site.dates} model={model} />
        </div>
      </div>
    ))}
  </>
);

AdditionalCampsites.propTypes = {
  model: PropTypes.object.isRequired,
  campsiteData: PropTypes.array.isRequired,
  facets: PropTypes.object.isRequired,
};

export default AdditionalCampsites;
