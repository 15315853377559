export default class ViewBookingsLoader {
  constructor(element) {
    ViewBookingsLoader.load(element);
  }

  static async load(element) {
    const { default: ViewBookings } = await import(/* webpackChunkName: "ViewBookings" */'../render/ViewBookings');

    new ViewBookings(element);
  }
}
