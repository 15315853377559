import InViewManager from '../../managers/in-view';

export default class ReadyCampBookingWidgetLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);
  }

  onInView = (element) => {
    if (!document.body.classList.contains('ExperienceEdit')) {
      ReadyCampBookingWidgetLoader.load(element);
    }
  }

  static async load(element) {
    const { default: ReadyCampBookingWidget } = await import(/* webpackChunkName: "ReadyCampBookingWidget" */'../modules/BookingWidget/ReadyCampBookingWidget');

    new ReadyCampBookingWidget(element);
  }
}
