import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import DatePicker from '../../Form/DatePicker';
import Icon from '../../Icon';

dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isBetween);

const DAY_NAMES = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const SearchForm = ({
  regions,
  campsites,
  model,
  updateQuery,
  queryParams,
  durationRules,
  seasonRules,
}) => {
  const [formState, setFormState] = useState(queryParams);
  const [filteredCampsites, setFilteredCampsites] = useState(campsites);
  const [mobileOpen, setMobileOpen] = useState(false);
  const durationRef = useRef(null);
  const campsiteRef = useRef(null);

  useEffect(() => {
    setFilteredCampsites(campsites);
  }, [campsites]);

  // Update form state from props
  useEffect(() => {
    setFormState(prev => ({ ...prev, ...queryParams }));
  }, [queryParams]);

  const updateDate = (date) => {
    setFormState(prev => ({
      ...prev,
      startDate: dayjs(date).format('YYYY-MM-DD'),
    }));
  };

  const getDurations = () => {
    let durations = Object.values(durationRules).flat();
    durations = Array.from(new Set(durations)).map(item => parseInt(item, 10));
    return durations.sort((a, b) => a - b);
  };

  const filterCampsites = (e) => {
    let filteredSites = campsites;
    if (e.target.value !== model.regionPlaceholder) {
      filteredSites = campsites.filter(site => site.RegionId === parseInt(e.target.value, 10));
    }
    setFilteredCampsites(filteredSites);
  };

  const disableFunction = (date) => {
    const day = date.getDay();

    // Disable to day if past 14:00
    if (dayjs(date).isToday()) {
      const currentDate = dayjs();
      if (currentDate.hour() >= 14) {
        return true;
      }
    }

    // Season rules
    // Find season rules for this campsite
    const currentCampsite = campsites.find(site => site.ItemId === campsiteRef.current.value);

    let disableDate = true;

    seasonRules
      .filter(x => x.SiteId === currentCampsite.Id)
      .flatMap(site => site.SeasonDates)
      .forEach((season) => {
        if (season){
          if (dayjs(date).isBetween(season.OpenDate, season.CloseDate, 'day', '[]')) {
            disableDate = false;
          }
        }
      });

    if (disableDate) {
      return true;
    }

    // Duration rules
    const parsedRules = durationRules;
    const rule = parsedRules[DAY_NAMES[day]];
    if (!rule) {
      return true;
    }
    return !rule.includes(durationRef.current.value);
  };

  const getDate = () => {
    return formState.startDate ? new Date(Date.parse(formState.startDate)) : null;
  };

  const updateState = (e) => {
    switch (e.target.name) {
      case 'duration': {
        setFormState({
          ...formState,
          startDate: null,
          duration: e.target.value,
        });
        break;
      }
      case 'siteId': {
        setFormState({
          ...formState,
          startDate: null,
          siteId: e.target.value,
        });
        break;
      }
      case 'region': {
        filterCampsites(e);
        setFormState({
          ...formState,
          startDate: null,
          duration: model.durationPlaceholder,
          siteId: model.campsitePlaceholder,
        });
        break;
      }
      default: {
        setFormState({
          ...formState,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const isValid = (name) => {
    // guard
    if (!name) return true;
    // Actual validation testing
    const value = formState[name];
    const defaultValues = [model.campsitePlaceholder, model.durationPlaceholder];
    if (!value || defaultValues.includes(value)) {
      return false;
    }
    return true;
  };

  const isFormValid = () => {
    const validity = Object.keys(formState).map(item => isValid(item));
    return validity.every(item => item);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      updateQuery(formState);
    }
  };

  return (
    <div className={`AvailabilitySearchForm ${mobileOpen ? 'AvailabilitySearchForm-modalOpen' : ''}`}>
      <div className="AvailabilitySearchForm_header">
        <p>Update your search results</p>
        <div className="AvailabilitySearchForm_icon" onClick={() => setMobileOpen(!mobileOpen)}>
          <Icon className="AvailabilitySearchForm_buttonIcon" name={mobileOpen ? 'MinusCollapse' : 'PlusExpand'} />
        </div>
      </div>
      <form
        className={`AvailabilityForm ${mobileOpen ? 'AvailabilityForm-open' : ''}`}
        data-form="form"
        autoComplete="off"
        action="@Model.Settings.AvailabilityResultsItem.Url">
        <div className="AvailabilityForm_fieldsets">
          <div className="AvailabilityForm_fieldset">
            <div className="AvailabilityForm_control">
              <select
                className="AvailabilityForm_select"
                id="selectDuration"
                name="region"
                value={formState.region}
                onChange={e => updateState(e)}>
                <option>{model.regionPlaceholder}</option>
                {regions.map(site => (
                  <option key={site.Id} value={site.Value}>
                    {site.Name}
                  </option>
                ))}
              </select>
              <Icon className="Form_selectArrowIcon Icon" name="Arrow" />
              <label className="AvailabilityForm_controlLabel" htmlFor="selectDuration">
                {model.regionLabel}
              </label>
            </div>
          </div>
          <div
            className={`AvailabilityForm_fieldset ${
              !isValid('siteId') ? 'AvailabilityForm_fieldset-errored' : ''
            }`}>
            <div className="AvailabilityForm_control">
              <select
                className="AvailabilityForm_select"
                id="selectCampsite"
                value={formState.siteId}
                name="siteId"
                ref={campsiteRef}
                onChange={updateState}>
                <option>{model.campsitePlaceholder}</option>
                {filteredCampsites.map(site => (
                  <option key={site.ItemId} value={site.ItemId}>
                    {site.Name}
                  </option>
                ))}
              </select>
              <Icon className="Form_selectArrowIcon Icon" name="Arrow" />
              <label className="AvailabilityForm_controlLabel" htmlFor="selectDuration">
                {model.campsiteLabel}
              </label>
              {!isValid('siteId') && (
                <span className="AvailabilityForm_validation" data-form="validation-error">
                  {model.campsiteValidationMessage}
                </span>
              )}
            </div>
          </div>
          <div
            className={`AvailabilityForm_fieldset ${
              !isValid('duration') ? 'AvailabilityForm_fieldset-errored' : ''
            }`}>
            <div className="AvailabilityForm_control">
              <select
                className="AvailabilityForm_select"
                id="selectDuration"
                name="duration"
                onChange={updateState}
                value={formState.duration}
                ref={durationRef}>
                <option>{model.durationPlaceholder}</option>
                {getDurations().map(duration => (
                  <option key={duration} value={duration}>
                    {duration} Nights
                  </option>
                ))}
              </select>
              <Icon className="Form_selectArrowIcon Icon" name="Arrow" />
              <label className="AvailabilityForm_controlLabel" htmlFor="selectDuration">
                {model.durationLabel}
              </label>
              {!isValid('duration') && (
                <span className="AvailabilityForm_validation" data-form="validation-error">
                  {model.durationValidationMessage}
                </span>
              )}
            </div>
          </div>
          <div
            className={`AvailabilityForm_fieldset ${
              !isValid('startDate') ? 'AvailabilityForm_fieldset-errored' : ''
            }`}
            style={{
              pointerEvents: isValid('duration') ? 'all' : 'none',
            }}
            >
            <DatePicker
              disabled={!isValid('siteId') && !isValid('duration')}
              disableDayFn={disableFunction}
              date={getDate()}
              placeholder={model.checkInPlaceholder}
              parentClass="AvailabilityForm"
              label={model.checkInLabel}
              name="startDate"
              onChange={e => updateDate(e)}
              isValid={isValid('startDate')}
              validationMessage={model.checkInDateValidationMessage}
            />
          </div>
          <div className="AvailabilityForm_submit">
            <button
              disabled={!isFormValid()}
              onClick={onSubmit}
              className={`AvailabilityForm_submitButton ${
                !isFormValid() ? 'AvailabilityForm_submitButton-disabled' : ''
              }`}>
              {model.buttonLabel}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

SearchForm.propTypes = {
  regions: PropTypes.array,
  campsites: PropTypes.array,
  queryParams: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  durationRules: PropTypes.object.isRequired,
  seasonRules: PropTypes.array.isRequired,
};

export default SearchForm;
