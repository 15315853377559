import InViewManager from '../../managers/in-view';

export default class MapLocationLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);
  }

  onInView = (element) => {
    MapLocationLoader.load(element);
  }

  static async load(element) {
    const { default: MapLocation } = await import(/* webpackChunkName: "MapLocation" */'../render/MapLocation');

    new MapLocation(element);
  }
}
