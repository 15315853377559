import PropTypes from 'prop-types';

export const campsiteDataPropTypes = PropTypes.shape({
  facilities: PropTypes.arrayOf(PropTypes.number).isRequired,
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  isMembersOnly: PropTypes.bool,
  latitude: PropTypes.number.isRequired,
  location: PropTypes.string,
  longitude: PropTypes.number.isRequired,
  overallRating: PropTypes.number,
  rating: PropTypes.number,
  reviewCount: PropTypes.number,
  shortDescription: PropTypes.string,
  unitTypes: PropTypes.arrayOf(PropTypes.number),
  url: PropTypes.string,
});

export const campsitesDataPropTypes = PropTypes.arrayOf(campsiteDataPropTypes);

export const facetTypePropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  iconClass: PropTypes.string,
  infoPopout: PropTypes.string,
});


export const facetTypesPropTypes = PropTypes.shape({
  campsiteTypes: PropTypes.arrayOf(facetTypePropTypes),
  facilities: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    facets: PropTypes.arrayOf(facetTypePropTypes),
  })),
  unitTypes: PropTypes.arrayOf(facetTypePropTypes),
});

export const facetStatsPropTypes = PropTypes.shape({
  facets: PropTypes.shape({
    unitTypeFacetStats: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      stat: PropTypes.number,
    })),
    facilityFacetStats: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      stat: PropTypes.number,
    })),
  }),
});


export const paramsPropTypes = PropTypes.shape({
  unitTypeIds: PropTypes.arrayOf(PropTypes.number),
  campsiteTypes: PropTypes.arrayOf(PropTypes.number),
  facilityIds: PropTypes.arrayOf(PropTypes.number),
  isOpenToAll: PropTypes.bool,
});

export const filtersModalPropTypes = PropTypes.shape({
  filterFiltersTitle: PropTypes.string.isRequired,
  filterBy: PropTypes.string.isRequired,
  filterByCampsiteType: PropTypes.string.isRequired,
  filterByUnitType: PropTypes.string.isRequired,
  filterByFacility: PropTypes.string.isRequired,
  filterResetButton: PropTypes.string.isRequired,
  filterResetAllButton: PropTypes.string.isRequired,
  filterBackButton: PropTypes.string.isRequired,
  filterCancelButton: PropTypes.string.isRequired,
  filterSaveButton: PropTypes.string.isRequired,
  filterShowOnlyTitle: PropTypes.string.isRequired,
  filterNoResultsWarning: PropTypes.string.isRequired,
});
