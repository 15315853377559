import InViewManager from '../../managers/in-view';

export default class AlternateBookingWidgetLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);
  }

  onInView = (element) => {
    if (!document.body.classList.contains('ExperienceEdit')) {
      AlternateBookingWidgetLoader.load(element);
    }
  }

  static async load(element) {
    const { default: AlternateBookingWidget } = await import(/* webpackChunkName: "AlternateBookingWidget" */'../modules/BookingWidget/AlternateBookingWidget');

    new AlternateBookingWidget(element);
  }
}
