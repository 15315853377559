export default class RatingInput {
  constructor(context) {
    this.context = context;

    this.initRatingInput();
  }


  initRatingInput() {
    this.display = this.context.querySelector('[data-rating-input-display]');
    this.select = this.context.querySelector('[data-rating-input-select]');
    this.width = this.display.offsetWidth;
    this.isUserInput = false;

    this.display.addEventListener('click', this.onClick);
    this.select.addEventListener('input', this.onInput);
  }


  onClick = (event) => {
    const x = event.offsetX;
    const starRating = Math.ceil((x / this.width) * 5);
    const displayRating = starRating * 2;

    this.display.className = `ReviewsForm_rating Rating Rating-${displayRating} Rating-large`;
    this.isUserInput = true;
    this.select.value = starRating;

    // trigger an event on the select so we can update validation
    const inputEvent = document.createEvent('Event');
    inputEvent.initEvent('input', true, true);
    this.select.dispatchEvent(inputEvent);
  }


  onInput = () => {
    if (!this.isUserInput) {
      this.display.className = `ReviewsForm_rating Rating Rating-${this.select.value * 2} Rating-large`;
    }

    this.isUserInput = false;
  }
}
