import InViewManager from '../../managers/in-view';

export default class VideoPlayerLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);

    if (document.body.classList.contains('ExperienceEdit')) {
      VideoPlayerLoader.load(element);
    }
  }

  onInView = (element) => {
    VideoPlayerLoader.load(element);
  }

  static async load(element) {
    const { default: VideoPlayer } = await import(/* webpackChunkName: "VideoPlayer" */'../render/VideoPlayer');

    new VideoPlayer(element);
  }
}
