import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

import ViewManager from '../../../managers/view';
import {
  loadImageBackground,
  updateImageSrcString,
  getBoundsData,
  isStillLazy,
} from '../../../util/image-load-helpers';


export default class LazyImage extends React.Component {
  constructor(props) {
    super(props);

    this.backgroundElementRef = React.createRef();
    this.elementRef = React.createRef();
    this.state = {
      isLoaded: false,
      isLoading: false,
    };
  }


  componentDidMount() {
    ViewManager.on(ViewManager.EVENT_WILL_UPDATE, this.onViewWillUpdate);
    ViewManager.invalidate();
  }


  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      ViewManager.invalidate();
    }
  }


  componentWillUnmount() {
    ViewManager.off(ViewManager.EVENT_WILL_UPDATE, this.onViewWillUpdate);
  }

  reset = () => {
    this.setState({
      isLoading: false,
      isLoaded: false,
      src: '',
    });

    ViewManager.on(ViewManager.EVENT_WILL_UPDATE, this.onViewWillUpdate);
  }

  onViewWillUpdate = (data) => {
    if (this.props.src && (data.scrollUpdate || data.resizeUpdate)) {
      const { isWithinBounds, width, height } = getBoundsData(this.backgroundElementRef.current);
      const isElementStillLazy = isStillLazy(this.elementRef.current);

      if (isWithinBounds && isElementStillLazy) {
        const src = updateImageSrcString(this.props.src, width, height, this.props.useMax);
        if (__DEVELOPMENT__) {
          console.log('LazyImage load image:', src);
        }

        ViewManager.off(ViewManager.EVENT_WILL_UPDATE, this.onViewWillUpdate);

        this.setState({
          src,
          isLoading: true,
        });

        loadImageBackground(src, (payload) => {
          if (__DEVELOPMENT__) {
            console.log('LazyImage loaded image:', payload.src);
          }
          // currently we can't cancel a loading image so check that component still exits.
          if (this.elementRef.current) {
            this.setState({
              isLoading: false,
              isLoaded: true,
              src: payload.src,
            });
          }
        });
      }
    }
  }


  render() {
    const classNames = ClassNames({
      [this.props.className]: true,
      LazyImage: true,
      'LazyImage--loading': this.state.isLoading,
      'LazyImage--loaded': this.state.isLoaded,
    });

    const style = {};
    if (this.state.isLoaded) {
      style.backgroundImage = `url("${this.state.src}")`;
    }

    return (
      <div className={classNames} ref={this.elementRef}>
        <div className="LazyImage_loadFeedback"></div>
        <div role="img"
          style={style}
          ref={this.backgroundElementRef}
          aria-label={this.props.alt}
          title={this.props.alt}
          className="LazyImage_background">
        </div>
      </div>
    );
  }
}


LazyImage.defaultProps = {
  alt: '',
  className: '',
  src: '',
  useMax: false,
};


LazyImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  useMax: PropTypes.bool,
};
