import InViewManager from '../../managers/in-view';

export default class PhoneNumberLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);
  }

  onInView = (element) => {
    PhoneNumberLoader.load(element);
  }

  static async load(element) {
    const { default: PhoneNumber } = await import(/* webpackChunkName: "PhoneNumber" */'../modules/PhoneNumber');

    new PhoneNumber(element);
  }
}
