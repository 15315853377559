import InViewManager from '../../managers/in-view';

export default class RegionsLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);

    if (document.body.classList.contains('ExperienceEdit')) {
      RegionsLoader.load(element);
    }
  }

  onInView = (element) => {
    RegionsLoader.load(element);
  }

  static async load(element) {
    const { default: Regions } = await import(/* webpackChunkName: "Regions" */'../render/Regions');

    new Regions(element);
  }
}
