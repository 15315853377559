/**
 * Requirements
 * - Stop default browser anchor scroll to anchor behaviour
 * - Check onload if anchor is present and manage scroll to anchor
 * - Hook in to history API to pick up change in Anchors
 */

import MotionTween from 'motion-tween';

import { getOffsetTop } from '../util/style';


class ScrollToAnchorManager {
  motionTween = undefined;

  destinationY = 0;

  destinationElement = undefined;

  hash = '';

  isAnimating = false;

  static get instance() {
    /* eslint-disable no-underscore-dangle */
    if (!window.__cccScrollToAnchorManager) {
      window.__cccScrollToAnchorManager = new ScrollToAnchorManager('singleton');
    }

    return window.__cccScrollToAnchorManager;
    /* eslint-enable no-underscore-dangle */
  }

  constructor(string) {
    this.initScrollToAnchorManager();

    if (string !== 'singleton') throw new Error('Cannot construct singleton');
  }


  initScrollToAnchorManager() {
    this.checkToScrollToAnchor();

    window.addEventListener('popstate', this.checkToScrollToAnchor);
    window.addEventListener('wheel', this.checkToCancel);
    window.addEventListener('touchstart', this.checkToCancel);
  }


  static removeHash(str) {
    return str.replace('#', '');
  }


  checkToCancel = () => {
    if (this.isAnimating && this.motionTween !== undefined) {
      this.motionTween.destroy();
      this.motionTween = undefined;
      this.isAnimating = false;
    }
  }


  checkToScrollToAnchor = () => {
    if (window.location.hash) {
      this.scrollToAnchor(window.location.hash);
    }
  }


  scrollToAnchor(anchorString) {
    anchorString = ScrollToAnchorManager.removeHash(anchorString);
    this.hash = anchorString;

    const anchorElement = document.querySelector(`[data-scroll-to-anchor-id="${anchorString}"]`);
    if (anchorElement) {
      const scrollingElement = document.scrollingElement || document.documentElement;

      this.destinationElement = anchorElement;
      this.destinationY = getOffsetTop(anchorElement);

      this.animateScroll(scrollingElement.scrollTop, this.destinationY);
    }
  }


  animateScroll(startValue, endValue) {
    if (this.motionTween !== undefined) {
      this.motionTween.destroy();
      this.motionTween = undefined;
    }

    this.isAnimating = true;

    this.motionTween = new MotionTween({
      startValue,
      endValue,
      update: this.onUpdateTween,
      complete: this.onComplete,
      animatorType: MotionTween.animatorType.friction,
      animatorOptions: {
        tolerance: 0.5,
      },
    }).start();
  }


  onUpdateTween = (value) => {
    const scrollingElement = document.scrollingElement || document.documentElement;

    if (this.destinationElement.offsetTop !== this.destinationY) {
      this.destinationY = getOffsetTop(this.destinationElement);
      setTimeout(() => {
        this.animateScroll(scrollingElement.scrollTop, this.destinationElement.offsetTop);
      }, 0);
    } else {
      scrollingElement.scrollTop = value;
    }
  }


  onComplete = () => {
    this.isAnimating = false;
  }
}

export default ScrollToAnchorManager.instance;
