export function getCookie(name) {
  const cookieArray = document.cookie.split(';');
  const cookie = cookieArray.find(c => c.includes(name));

  return cookie;
}

export function setCookie(name, value, expiryDate) {
  document.cookie = `${name}=${value}; expires=${expiryDate}; secure`;
}
