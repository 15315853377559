import ViewManager from './view';

const inViewBoundarySize = 15; // in vh

class InViewManager {
  static get instance() {
    /* eslint-disable no-underscore-dangle */
    if (!window.__cccInViewManager) {
      window.__cccInViewManager = new InViewManager('singleton');
    }

    return window.__cccInViewManager;
    /* eslint-enable no-underscore-dangle */
  }

  elementsMap = new Map();

  updateQueue = [];


  constructor(string) {
    this.init();

    if (string !== 'singleton') throw new Error('Cannot construct singleton');
  }


  static isInView(element) {
    const { top, bottom, height } = element.getBoundingClientRect();
    let boundary = inViewBoundarySize;
    if (element.dataset.inViewBoundarySize) {
      boundary = parseInt(element.dataset.inViewBoundarySize, 10);
    }
    boundary = (boundary / 100) * window.innerHeight;

    return height
      && (bottom > -boundary && top < window.innerHeight + boundary);
  }


  init() {
    // event phase to make measurements of the dom
    ViewManager.on(ViewManager.EVENT_WILL_UPDATE, this.onViewWillUpdate);
    // event phase to actually manipulate the dom
    ViewManager.on(ViewManager.EVENT_UPDATE, this.onViewUpdate);
    ViewManager.invalidate();
  }


  add(element, callback = undefined) {
    if (this.elementsMap.has(element)) {
      const callbacksSet = this.elementsMap.get(element);

      if (!callbacksSet.has(callback)) {
        callbacksSet.add(callback);
      }
    } else {
      const callbacksSet = callback ? new Set([callback]) : new Set();
      this.elementsMap.set(element, callbacksSet);
    }
  }


  remove(element, callback = undefined) {
    if (this.elementsMap.has(element)) {
      const callbacksSet = this.elementsMap.get(element);

      if (callback && callbacksSet.has(callback)) {
        callbacksSet.delete(callback);

        if (callbacksSet.size === 0) {
          this.elementsMap.delete(element);
        }
      } else {
        callbacksSet.clear();
        this.elementsMap.delete(element);
      }
    }
  }


  /**
   * onViewWillUpdate ascertain if the element is within the view.
   * Remember, when using the ViewManager.EVENT_WILL_UPDATE to only
   * read measurements from the dom not manipulate.
   */
  onViewWillUpdate = () => {
    this.updateQueue = [];

    this.elementsMap.forEach((setValue, element) => {
      if (InViewManager.isInView(element)) {
        this.updateQueue.push(element);
      }
    });
  }


  /**
   * onViewUpdate if we have any elements in the queue, then we are good
   * to update the dom. Remember, the ViewManager.EVENT_UPDATE event phase
   * is for manipulating the dom.
   */
  onViewUpdate = () => {
    this.updateQueue.forEach((element) => {
      element.classList.add('js-in-view-triggered');
      const callbacksSet = this.elementsMap.get(element);
      callbacksSet.forEach(callback => callback(element));
      this.remove(element);
    });
  }
}

export default InViewManager.instance;
