export default class MemberFlag {
    constructor(context) {
      this.context = context;
  
      this.initMemberFlag();
    }
  
    initMemberFlag() {
        const campsiteFlagDesktop = 'MembersOnlyFlag-campsiteDesktop';
        const campsiteFlagMobile = 'MembersOnlyFlag-campsiteMobile';
        this.memberFlags = [...this.context.querySelectorAll('[data-member-flag]')];
        this.campsitePage = this.context.querySelector('.CampsiteHeader');
        this.lastMemberFlagIndex = this.memberFlags.length -1;
        
        if (this.memberFlags.length >= 1 && !this.campsitePage) {
            this.displayLastMemberFlag();
        }
        // On a campsite page, flag displayed within CampsiteTop on desktop
        // Displays below CampsiteMenu on tablet/mobile view
        if (this.campsitePage) {
            this.memberFlags.forEach((memberFlag, index) => {
                // Ignore flag behind header
                if (index === 1) {
                    memberFlag.classList.add(campsiteFlagDesktop);
                } else if (index === this.lastMemberFlagIndex) {
                    memberFlag.classList.add(campsiteFlagMobile);
                }
            });
        }
    }

    displayLastMemberFlag() {
        const memberFlagClass = 'MembersOnlyFlag-disabled';
        this.memberFlags.forEach((memberFlag, index) => {
            if (index === this.lastMemberFlagIndex) {
                memberFlag.classList.remove(memberFlagClass);
            }
        });
    }
}  