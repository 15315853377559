import InViewManager from '../../managers/in-view';

export default class ArticlesLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);
  }

  onInView = (element) => {
    if (!document.body.classList.contains('ExperienceEdit')) {
      ArticlesLoader.load(element);
    }
  }

  static async load(element) {
    const { default: Articles } = await import(/* webpackChunkName: "Articles" */'../modules/Articles');

    new Articles(element);
  }
}
