import ViewManager from '../../../managers/view';
import Rectangle from '../../../util/geom/rectangle';
import { getOffsetTop, getOffsetLeft, pageX } from '../../../util/style';
import { closest } from '../../../util/dom';

export default class PopOut {
  static previousPopOut;

  constructor(element) {
    this.element = element;

    this.container = null;

    this.parentContainer = null;

    this.containerSource = null;

    this.parentContainerSelector = null;

    this.arrow = null;

    this.control = null;

    this.hit = null;

    this.isOpen = false;

    this.animationOffsetY = -6;

    this.init();
  }


  init() {
    if (this.element) {
      this.activate();
    }
  }


  updateReactRef = (element) => {
    if (this.element) {
      this.deactivate();
    }
    this.element = element;
    if (this.element) {
      this.activate();
    }
  }


  activate() {
    this.hit = this.element.querySelector('[data-pop-out="hit"]') || this.element;
    this.hitParent = this.element.querySelector('[data-pop-out="hit-parent"]');
    if (this.hitParent && this.hitParent.children.length) {
      this.hit = this.hitParent.children[0];
    }
    this.hitTrigger = this.element.querySelector('[data-pop-out="hit-trigger"]') || this.element;
    this.control = this.element.querySelector('[data-pop-out="control"]') || this.element;
    this.containerSource = this.element.querySelector('[data-pop-out="container"]');
    this.parentContainerSelector = this.element.dataset.popOutParentContainerSelector || null;

    // for iOS we use the div below body to attach listener to close popup
    this.parentBeforeBody = PopOut.getParentBeforeBody(this.element);

    this.hit.addEventListener('click', this.onClickControl);
    if (this.hit !== this.hitTrigger) {
      this.hitTrigger.addEventListener('click', this.onTriggerClick);
    }
  }


  deactivate() {
    this.hit.removeEventListener('click', this.onClickControl);
    this.hitTrigger.removeEventListener('click', this.onTriggerClick);
  }


  destroy() {
    if (this.element) {
      this.deactivate();
      this.close();
    }
    this.element = null;
  }


  onTriggerClick = (event) => {
    event.stopPropagation();
    this.hit.click();
  }


  onClickControl = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.isOpen) {
      this.close();
    } else {
      PopOut.clearAnyPreviousPopOut();
      if (this.containerSource) {
        this.open();
        // ensure adding of event listener happens outside of this call stack, on chrome
        // popouts added within labels that activate checkboxes the onClickDocument is
        // being called as I guess we have yet to get to that bubble level and the
        // added onClickDocument is then actually called.
        setTimeout(() => {
          this.parentBeforeBody.addEventListener('click', this.onClickDocument);
        }, 0);
      }
    }
  }


  onClickDocument = () => {
    if (this.isOpen) {
      this.close();
    }
  }


  static getParentScrollingContainer(element) {
    // iterate over parent nodes, to get scroll container
    let parentContainer = element.parentNode;
    while (parentContainer !== document.body) {
      parentContainer = parentContainer.parentNode;
      if (PopOut.checkIfScrollContainer(parentContainer)) {
        break;
      }
    }

    return parentContainer;
  }


  static checkIfScrollContainer(element) {
    const canScroll = window.getComputedStyle(element)['overflow-y'] === 'auto' || window.getComputedStyle(element)['overflow-y'] === 'scroll';
    const isTooBig = element.scrollHeight > element.offsetHeight;
    return isTooBig && canScroll;
  }


  static getParentBeforeBody(element) {
    // iterate over parent nodes, to get scroll container
    let parentContainer = element.parentNode;
    while (parentContainer.parentNode !== document.body) {
      parentContainer = parentContainer.parentNode;
    }

    return parentContainer;
  }

  static clearAnyPreviousPopOut() {
    if (PopOut.previousPopOut) {
      PopOut.previousPopOut.close();
    }
  }


  layout = () => {
    this.container.style.position = 'absolute';
    this.container.style.top = 0;
    this.container.style.left = 0;

    const containerWidth = this.container.offsetWidth;
    const containerHeight = this.container.offsetHeight;
    const controlGlobalRect = new Rectangle(
      pageX(this.hit, true),
      getOffsetTop(this.hit),
      this.hit.offsetWidth,
      this.hit.offsetHeight,
    );
    const containerGlobalRect = new Rectangle(
      getOffsetLeft(this.parentContainer),
      getOffsetTop(this.parentContainer),
      this.parentContainer.offsetWidth,
      this.parentContainer.offsetHeight,
    );
    const controlRelativeX = controlGlobalRect.left - containerGlobalRect.left;
    const controlRelativeY = controlGlobalRect.top - containerGlobalRect.top;

    const marginX = 8;
    const marginTop = 12;
    const marginBottom = marginTop;

    let x = controlRelativeX + (controlGlobalRect.width / 2) - (containerWidth / 2);
    let y = (controlRelativeY) - (containerHeight + marginTop);
    let animationOffsetY = this.animationOffsetY * -1;

    const offsetX = this.parentContainer.offsetWidth - (x + containerWidth + marginX);

    const hitBox = this.hit.getBoundingClientRect();
    const hitBoxCenter = Math.abs(hitBox.width / 2);

    if (containerHeight > hitBox.top || this.container.classList.contains('PopOut-belowOnly')) {
      y = (controlRelativeY) + (marginBottom + hitBox.height);
      animationOffsetY = this.animationOffsetY;
    }

    if (offsetX < 0) {
      x += offsetX;
    } else if (x < marginX) {
      x = marginX;
    }

    this.container.style.transform = `translate(${x}px, ${y + animationOffsetY}px)`;
    const containerBox = this.container.getBoundingClientRect();

    const arrowHeight = 10; // arrowHeight & arrowWidth values taken from _pop-out.scss
    const arrowWidth = 7;
    let arrowTop;
    let arrowX;
    let arrowRotation = 0;

    if (offsetX < 0) {
      arrowX = (hitBox.left - containerBox.left) + hitBoxCenter - arrowWidth;
    } else if (x === marginX) {
      arrowX = (hitBox.left - marginX - containerGlobalRect.left) + (hitBoxCenter - arrowWidth);
    } else {
      arrowX = (containerBox.width / 2) - arrowWidth;
    }

    if (containerHeight > hitBox.top  || this.container.classList.contains('PopOut-belowOnly')) {
      arrowRotation = 180;
      arrowTop = -(arrowHeight * 2);
    }

    if (arrowTop) {
      this.arrow.style.top = `${arrowTop}px`;
    }

    this.arrow.style.transform = `translateX(${arrowX}px) rotate(${arrowRotation}deg)`;
    this.container.classList.add('PopOut-active');
    this.container.style.transform = `translate(${x}px, ${y}px)`; // reset container
  }


  open() {
    // store ourself in a statically accessible property.
    PopOut.previousPopOut = this;

    this.isOpen = true;
    this.control.classList.add('PopOut-active');

    // Iterate up dom tree to determine if we are within a container that can scroll,
    // default will be body
    if (this.parentContainerSelector) {
      this.parentContainer = closest(this.element, this.parentContainerSelector);
    } else {
      this.parentContainer = PopOut.getParentScrollingContainer(this.element);
    }

    // x and y should be relative to the scroll parent
    this.container = this.containerSource.cloneNode(true);
    this.parentContainer.appendChild(this.container);

    this.arrow = document.createElement('div');
    this.container.appendChild(this.arrow);
    this.arrow.classList.add('PopOut_arrow');

    // Hosting container can't be static if not body.
    if (__DEVELOPMENT__) {
      if (this.parentContainer !== document.body && window.getComputedStyle(this.parentContainer).position === 'static') {
        // eslint-disable-next-line
        console.warn('PopOut: popup hosting containers position is static, positioning maybe affected!');
      }
    }

    this.layout();

    ViewManager.on(ViewManager.EVENT_UPDATE, this.onViewUpdate);
  }


  close() {
    this.parentBeforeBody.removeEventListener('click', this.onClickDocument);
    this.isOpen = false;
    this.control.classList.remove('PopOut-active');

    PopOut.previousPopOut = undefined;

    if (this.container && this.container.parentElement) {
      this.container.parentElement.removeChild(this.container);
    }

    ViewManager.off(ViewManager.EVENT_UPDATE, this.onViewUpdate);
  }


  onViewUpdate = (data) => {
    if (data.resizeUpdate) {
      this.layout();
    }
  }
}
