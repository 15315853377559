import React from 'react';
import PropTypes from 'prop-types';
import CampsiteCard from '../../Regions/components/CampsiteCard';
import DateGrid from './DateGrid';

const PrimaryCampsite = ({ campsite, model, facets }) => {
  if (Object.keys(campsite).length === 0) return <></>;
  return (
    <>
      <div className="Campsites_left">
        <h3 className="Campsites_title">Availability & Booking</h3>
        <div className="Campsites_site">
          <div className="Campsites_card">
            <CampsiteCard
              layoutType="regions"
              campsiteData={campsite}
              labels={model}
              showSubmit={false}
              showReviews={false}
              isClickable={false}
              showReviews={false}
              facetTypes={facets}
            />
          </div>
        </div>
      </div>
      <div className="Campsites_right">
        <DateGrid dates={campsite.dates} model={model} />
      </div>
    </>
  );
};

PrimaryCampsite.propTypes = {
  campsite: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  facets: PropTypes.object.isRequired,
};

export default PrimaryCampsite;
