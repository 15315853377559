import Navigation from '../Navigation';
import ViewManager from '../../../managers/view';

export default class Header {
  constructor(element) {
    this.element = element;

    this.activeButton = undefined;
    this.navigation = undefined;
    this.timeoutId = undefined;
    this.toggleControl = this.toggleControl.bind(this);
    this.initHeader();
  }


  initHeader() {
    const notifications = [...this.element.querySelectorAll('[data-header-notification-id]')];
    notifications.forEach((notification) => {
      const id = notification.dataset.headerNotificationId;
      const notificationCookie = `notification-${id}`;
      const button = notification.querySelector('[data-header-notification-header-button]');
      if (button) {
        button.addEventListener('click', () => {
          document.cookie = notificationCookie;
          notification.classList.remove('active');
          ViewManager.invalidate(); // removal affects header height that other components read
        });
      }
    });

    const mobileButtons = [...this.element.querySelectorAll('[data-header*="mobile-button"]')];

    mobileButtons.forEach((mobileButton) => {
      mobileButton.addEventListener('click', () => {
        const isSearchButton = mobileButton.dataset.header.indexOf('mobile-search') > -1;
        const isMenuButton = mobileButton.dataset.header.indexOf('mobile-menu') > -1;
        const isLoginButton = mobileButton.dataset.header.indexOf('mobile-login') > -1;
        const navigationElement = this.element.querySelector('[data-header-navigation]');

        document.documentElement.classList.remove('showMobileSearch');
        document.documentElement.classList.remove('showMobileMenu');
        document.documentElement.classList.remove('showMobileLoginMenu');

        if (this.activeButton) {
          this.activeButton.classList.remove('active');
          this.lowerZIndex();
        }

        if (this.activeButton !== mobileButton) {
          navigationElement.style.display = isMenuButton ? 'block' : 'none';

          this.activeButton = mobileButton;
          mobileButton.classList.add('active');
        } else {
          this.activeButton = undefined;
          mobileButton.classList.remove('active');

          // timeOut added so that the header menu text isn't visible during the hiding animation
          if (isMenuButton) {
            setTimeout(() => {
              navigationElement.style.display = 'none';
            }, 300);
          }
        }

        if (this.activeButton) {
          this.raiseZIndex();
          if (isSearchButton) {
            document.documentElement.classList.add('showMobileSearch');
          } else {
            document.documentElement.classList.remove('showMobileSearch');
          }

          if (isMenuButton) {
            document.documentElement.classList.add('showMobileMenu');
          } else {
            document.documentElement.classList.remove('showMobileMenu');
          }

          if (isLoginButton) {
            document.documentElement.classList.add('showMobileLoginMenu');
          } else {
            document.documentElement.classList.remove('showMobileLoginMenu');
          }
        }
      });
    });

    const loggedInButton = this.element.querySelector('[data-header*="logged-in-button"]');
    const loggedInDropdown = this.element.querySelector('[data-header*="logged-in-dropdown"]');

    if (loggedInButton) {
      loggedInButton.addEventListener('click', () => {
        this.toggleControl(loggedInDropdown);
        this.toggleControl(loggedInButton);
      });
    }

    const navigationElement = this.element.querySelector('[data-header-navigation]');
    if (navigationElement) {
      this.navigation = new Navigation(navigationElement);
      this.navigation.on(Navigation.OPEN_EVENT, this.onNavigationOpen);
      this.navigation.on(Navigation.CLOSE_EVENT, this.onNavigationClose);
    }
  }

  toggleControl = (item) => {
    if (item.classList.contains('active')) {
      item.classList.remove('active');
    } else {
      item.classList.add('active');
    }
  }

  onNavigationOpen = () => {
    this.raiseZIndex();
  }

  onNavigationClose = () => {
    this.lowerZIndex();
  }

  raiseZIndex() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.element.classList.add('Header-menuActive');
    }, 200);
  }

  lowerZIndex() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.element.classList.remove('Header-menuActive');
    }, 200); // transition time set in _navigation.scss
  }
}
