export default class RequestMembershipCard {
  constructor(context) {
    this.context = context;
    this.requestMembershipCardBtn = undefined;
    this.requestMembershipCardMessage = undefined;
    this.init();
  }

  init() {
    this.requestMembershipCardBtn = this.context.querySelector('#requestMembershipCardBtn');
    this.requestMembershipCardMessage = this.context.querySelector('#requestMembershipCardMessage');
    this.successMessage = document.querySelector('.RequestMembershipCard_feedback-success');
    this.failureMessage = document.querySelector('.RequestMembershipCard_feedback-failure');

    if (this.requestMembershipCardBtn) {
      this.requestMembershipCardBtn.addEventListener('click', this.requestNewMembershipCard);
    }
  }

  showFeedback = (container) => {
    container.style.display = 'flex';
  }

  requestNewMembershipCard = () => {
    this.requestMembershipCardBtn.disabled = true;
    fetch(`${this.requestMembershipCardBtn.dataset.requestMembershipCardApi}`, { method: 'POST' })
      .then((response) => {
        if (response.status === 200) {
          this.showFeedback(this.successMessage);
        } else {
          this.showFeedback(this.failureMessage);
        }
      })
      .catch(() => {
        this.showFeedback(this.failureMessage);
      })
      .finally(() => {
        this.requestMembershipCardBtn.disabled = false;
      });
  }
}
