import React from 'react';
import PropTypes from 'prop-types';

const LoadingBeacon = ({ isDark }) => (
  <div className="LoadingBeacon">
    <div className={`LoadingBeacon_loadingDiscs${isDark ? ' LoadingBeacon_loadingDiscs-dark' : ''}`}>
      <div className="LoadingBeacon_loadingDisc"></div>
      <div className="LoadingBeacon_loadingDisc"></div>
      <div className="LoadingBeacon_loadingDisc"></div>
    </div>
  </div>
);

LoadingBeacon.propTypes = {
  isDark: PropTypes.bool,
};

LoadingBeacon.defaultProps = {
  isDark: false,
};

export default LoadingBeacon;
