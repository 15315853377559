import InViewManager from '../../managers/in-view';

export default class BookingWidgetLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);
  }

  onInView = (element) => {
    if (!document.body.classList.contains('ExperienceEdit')) {
      BookingWidgetLoader.load(element);
    }
  }

  static async load(element) {
    const { default: BookingWidget } = await import(/* webpackChunkName: "BookingWidget" */'../modules/BookingWidget');

    new BookingWidget(element);
  }
}
