import React from 'react';
import PropTypes from 'prop-types';

const Banner = ({ children }) => (
  <div className="Banner">
    <div className="Banner_content">{children}</div>
  </div>
);

Banner.propTypes = {
  children: PropTypes.node,
};

export default Banner;
