/**
 * Script to check for and initialise Sentry.
 *
 * The const string below should be present in Octopus for them to be replaced
 * within the app file upon deployment.
 */


import * as Sentry from '@sentry/browser';

const dsn = '#{SentryDsn}';
const environment = '#{Octopus.Environment.Name}';
const release = '#{Octopus.Release.Number}';

const scripts = [...document.scripts];

let friendlyName = 'unknown';
if (process && process.env && process.env.USERNAME) {
  friendlyName = process.env.USERNAME;
}

const sentryScript = scripts.find(script => script.src && script.src.indexOf('sentry-cdn.com') > -1);

if (Sentry && sentryScript) {
  if (dsn.indexOf('@sentry.io') > -1) {
    Sentry.init({ dsn, release, environment });
  }
  Sentry.configureScope((scope) => {
    scope.setUser({ username: friendlyName });
  });
}
