import InViewManager from '../../managers/in-view';

export default class FaqsLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);

    if (document.body.classList.contains('ExperienceEdit')) {
      FaqsLoader.load(element);
    }
  }

  onInView = (element) => {
    FaqsLoader.load(element);
  }

  static async load(element) {
    const { default: Faqs } = await import(/* webpackChunkName: "Faqs" */'../render/Faqs');

    new Faqs(element);
  }
}
