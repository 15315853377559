import React, { useEffect, useState } from 'react';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import SearchForm from './components/SearchForm';
import getData from './helpers/request';
import getQuery from './helpers/query';
import Campsites from './components/Campsites';
import Banner from './components/Banner';
import LoadingBeacon from '../LoadingBeacon';

const requiredParams = ['startDate', 'siteId', 'duration'];

const Availability = ({
  endpoint,
  durationRules,
  seasonRules,
  model,
  campsites,
  regions,
  facets,
}) => {
  const [queryParams, setQueryParams] = useState(getQuery());
  const [campsiteData, setCampsiteData] = useState({});
  const [campsiteError, setCampsiteError] = useState(null);
  const [dateErrorCode, setDateErrorCode] = useState(0);
  const [loading, setLoading] = useState(true);

  const resetState = () => {
    setLoading(true);
    setCampsiteError(null);
    setDateErrorCode(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        resetState();
        // Check if all required params are present
        const containsRequired = requiredParams.every(el => Object.keys(queryParams).includes(el));
        if (!containsRequired) {
          setCampsiteError('Missing search parameters');
          setLoading(false);
          return;
        }
        const res = await getData(endpoint, queryParams, durationRules, seasonRules, setLoading);
        if (!res) return;
        if (Object.keys(res).includes('error')) {
          setCampsiteError(res.error);
        } else {
          setCampsiteData(res);
        }
      } catch (error) {
        setCampsiteError(error.message);
      }
    };
    fetchData();
  }, [endpoint, durationRules, seasonRules, campsites, queryParams]);

  const updateQuery = (newParams) => {
    // Need to update the URL
    const stringified = QueryString.stringify(newParams);
    const uri = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${stringified}`;
    // reset to initial state
    resetState();
    // Update query (don't refresh)
    setQueryParams(newParams);
    window.history.pushState({ path: uri }, '', uri);
  };

  // Used to decode error codes from date validity checking
  const renderErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 1:
        return model.invalidDateValidationMessage;
      case 2:
        return model.outOfSeasonValidationMessage;
      case 3:
        return model.invalidDateValidationMessage;
      default:
        return 'Invalid date';
    }
  };

  return (
    <div className="Availability">
      <SearchForm
        regions={regions}
        campsites={campsites}
        model={model}
        queryParams={queryParams}
        updateQuery={updateQuery}
        durationRules={JSON.parse(durationRules)}
        seasonRules={JSON.parse(seasonRules)}
      />
      {loading && (
        <div className="AvailabilityLoading">
          <LoadingBeacon isDark={true} />
        </div>
      )}
      {Object.keys(campsiteData).includes('primaryCampsite') && !loading && !campsiteError && dateErrorCode === 0 ? (
        <Campsites model={model} campsites={campsiteData} facets={facets} />
      ) : (
        !loading && (
          <div className="AvailabilityError">
            <span className="AvailabilityError_message">{model.noResultsText}</span>
            <span className="AvailabilityError_subtext">{dateErrorCode ? renderErrorMessage(dateErrorCode) : campsiteError}</span>
          </div>
        )
      )}
      <Banner>{model.notificationBarLabel}</Banner>
    </div>
  );
};

Availability.propTypes = {
  endpoint: PropTypes.string.isRequired,
  durationRules: PropTypes.string.isRequired,
  seasonRules: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  campsites: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  facets: PropTypes.object.isRequired,
};

export default Availability;
