import '@babel/polyfill';
import './util/sentry';
import svg4everybody from 'svg4everybody';

import * as components from './components';
import './util/font-tracking';

import ViewManager from './managers/view';

// Typically ViewManager is activated in Body.js
// but for Frontify we don't have inline Body.js and therefore we activate here.
if (!ViewManager.active) {
  ViewManager.activate();
}

if (module.hot) module.hot.accept();

svg4everybody();

// Set the flag to show the JS is loaded
window.isJsLoaded = true;

[...document.querySelectorAll('[data-component]')].forEach((element) => {
  const componentName = element.getAttribute('data-component');

  const isValidName = Object.prototype.hasOwnProperty.call(components, componentName);
  if (!isValidName) throw Error(`Invalid component: ${componentName}`);

  const isValidConstructor = typeof components[componentName] === 'function';
  if (!isValidConstructor) throw Error(`Invalid constructor: ${componentName}`);

  return new components[componentName](element);
});
