const handleScroll = (stateUpdate) => {
  // idea here is to show absolute dates field once we scroll past, and hide when we go above.
  const el = document.querySelector('.DateCard_upper');
  const footer = document.querySelector('.Footer');
  if (!el || !footer) return;
  if (window.scrollY > el.offsetTop) {
    // hide if too far
    if (window.scrollY > (footer.offsetTop - 250)) {
      stateUpdate(false);
      return;
    }
    stateUpdate(true);
    return;
  }
  stateUpdate(false);
};

const handleWindowChange = () => {
  // Keep fixed dates element at same width as container
  const el = document.querySelector('.DateCard_upper');
  const fixedEl = document.querySelectorAll('.DateCard_upper-fixed');
  fixedEl.forEach((elem) => {
    elem.style.width = `${el.offsetWidth}px`;
  });
};

module.exports = {
  handleScroll,
  handleWindowChange,
};
