import Accordion from '../Accordion';
import ViewManager from '../../../managers/view';
import { desktopBreakpoint } from '../../../constants/breakpoints';

export default class TextAccordion extends Accordion {
  truncatedContentHeight;

  constructor(context) {
    super(context);

    this.initTextAccordion();
  }

  initTextAccordion() {
    this.onViewUpdate = this.onViewUpdate.bind(this);
    this.container = this.context.querySelector('[data-accordion="container"]');
    this.absoluteWrapper = this.context.querySelector('[data-accordion="absoluteWrapper"]');

    this.checkToTruncateContent();

    ViewManager.on(ViewManager.EVENT_UPDATE, this.onViewUpdate);
  }

  onViewUpdate(event) {
    if (event.resizeUpdate) {
      if (ViewManager.hasTransitionedBelow(desktopBreakpoint)) {
        this.checkToTruncateContent();
      }

      if (ViewManager.hasTransitionedAboveAndWithin(desktopBreakpoint)) {
        this.checkToTruncateContent();
      }

      this.checkToTruncateContent();
    }
  }

  static getLineHeight(element) {
    const lineHeightString = getComputedStyle(element).getPropertyValue('line-height');
    let lineHeightNumber = parseInt(lineHeightString, 10);

    if (String('normal|initial|inherit').indexOf(lineHeightString) > -1) {
      // very specific case
      lineHeightNumber = parseInt(getComputedStyle(element).getPropertyValue('font-size'), 10) + 2;
    }

    return lineHeightNumber;
  }

  truncateContent(content, lineHeight, linesToDisplay) {
    if (lineHeight && linesToDisplay) {
      this.truncatedContentHeight = linesToDisplay * lineHeight;
      content.style.height = `${this.truncatedContentHeight}px `;
    }
  }


  onClickControl(accordionControlItem) {
    let start = 0;
    let end = 0;

    const controlIndex = this.accordionControlItems.indexOf(accordionControlItem);
    const accordionItem = this.accordionContentItems[controlIndex];

    if (accordionControlItem.parentElement) {
      if (accordionControlItem.parentElement.classList.contains(this.toggleClass)) {
        accordionControlItem.parentElement.classList.remove(this.toggleClass);
        start = accordionItem.offsetHeight;
        this.isActiveContentOpen = true;

        if (this.truncatedContentHeight) {
          end = this.truncatedContentHeight;
        }
      } else {
        accordionControlItem.parentElement.classList.add(this.toggleClass);
        const initialHeight = accordionItem.offsetHeight;
        accordionItem.style.height = 'auto';
        if (this.truncatedContentHeight) {
          start = this.truncatedContentHeight;
        }
        window.getComputedStyle(accordionItem);
        end = accordionItem.offsetHeight;
        accordionItem.style.height = String(initialHeight);
        window.getComputedStyle(accordionItem);
        this.isActiveContentOpen = false;
      }

      this.selectedIndex = controlIndex;
      this.animate(start, end, controlIndex);
    }
  }

  setActiveState(index) {
    if (index === this.selectedIndex) {
      if (!this.isActiveContentOpen) {
        this.accordionContentItems[index].style.height = 'auto';
      } else {
        this.accordionContentItems[index].style.height = `${this.truncatedContentHeight}`;
      }
    } else {
      this.accordionContentItems[index].style.height = `${this.truncatedContentHeight}px`;
    }
    this.isContentAnimating = false;
    this.togglePointerEvents();
  }


  hideControl() {
    this.accordionControlItems[0].classList.add('hide');
  }

  showControl() {
    this.accordionControlItems[0].classList.remove('hide');
  }

  checkToTruncateContent() {
    let contentLines;
    const content = this.accordionContentItems[0];
    const control = this.accordionControlItems[0];

    this.container.classList.add('reset');
    window.getComputedStyle(this.container);
    const lineHeight = TextAccordion.getLineHeight(content);
    let linesToDisplay = Math.floor(this.container.offsetHeight / lineHeight);

    if (lineHeight) {
      content.style.height = 'auto';
      contentLines = content.offsetHeight / lineHeight;
    }

    if (contentLines && linesToDisplay && contentLines > linesToDisplay) {
      linesToDisplay = Math.floor(
        (this.container.offsetHeight - control.offsetHeight) / lineHeight,
      );

      this.truncateContent(content, lineHeight, linesToDisplay);
      this.showControl();
    } else {
      this.hideControl();
    }

    this.container.classList.remove('reset');
    window.getComputedStyle(this.container);
  }
}
