export default class CheckboxList {
  constructor(element) {
    this.element = element;

    this.initCheckboxList();
  }

  initCheckboxList() {
    this.maxSelected = Number(this.element.dataset.checkboxMaxSelected);
    this.inputs = this.element.querySelectorAll('input');

    this.inputs.forEach((input) => {
      input.addEventListener('click', () => this.checkMaxSelected(input));
    });
  }

  checkMaxSelected = (clickedInput) => {
    let inputCheckCount = 0;
    this.inputs.forEach((input) => {
      if (input.checked) {
        inputCheckCount += 1;
      }
    });

    if (clickedInput.checked && inputCheckCount > this.maxSelected) {
      clickedInput.checked = false;
    }
  }
}
