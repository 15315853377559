import MotionTween from 'motion-tween';

const animationTimeFast = 100;
const animationTimeDefault = 175;

export default class Accordion {
  constructor(context) {
    this.context = context;
    this.accordionMotionTweenItems = [];
    this.toggleClass = 'active';
    this.isActiveContentOpen = false;
    this.isContentAnimating = false;
    this.selectedIndex = -1;
    this.animateTime = animationTimeDefault;

    this.initAccordion();
  }


  initAccordion() {
    this.onUpdateTween = this.onUpdateTween.bind(this);
    this.setActiveState = this.setActiveState.bind(this);

    // ability to disable component in experience editor;
    if (
      this.context.dataset.accordionExperienceEditor === 'disable'
      && document.body.classList.contains('ExperienceEdit')
    ) return;

    this.accordions = [...this.context.querySelectorAll('[data-accordion="accordion"]')];
    this.accordionControlItems = [...this.context.querySelectorAll('[data-accordion="control"]')];
    this.accordionContentItems = [...this.context.querySelectorAll('[data-accordion="content"]')];

    this.accordionControlItems.forEach((accordionControlItem) => {
      accordionControlItem.addEventListener('click', () => this.onClickControl(accordionControlItem));

      if (accordionControlItem.dataset.openOnLoad && accordionControlItem.dataset.openOnLoad.toLowerCase() === 'true') {
        this.onClickControl(accordionControlItem);
      }
    });
  }


  onClickControl(accordionControlItem) {
    let start = 0;
    let end = 0;
    let showAnimation = true;

    const controlIndex = this.accordionControlItems.indexOf(accordionControlItem);
    const accordionItem = this.accordionContentItems[controlIndex];

    if (accordionControlItem.parentElement.classList.contains(this.toggleClass)) {
      accordionControlItem.parentElement.classList.remove(this.toggleClass);
      start = accordionItem.offsetHeight;
      this.isActiveContentOpen = true;
    } else {
      accordionControlItem.parentElement.classList.add(this.toggleClass);
      accordionItem.style.height = 'auto';
      window.getComputedStyle(accordionItem);
      end = accordionItem.offsetHeight;
      this.isActiveContentOpen = false;
    }

    if (this.selectedIndex !== -1 && this.selectedIndex !== controlIndex) {
      if (
        this.accordionContentItems[this.selectedIndex].parentElement.classList.contains(
          this.toggleClass,
        )
      ) {
        if (this.selectedIndex < controlIndex) { // to fix a scroll issue if the accordion is below an already open accordion
          showAnimation = false;
          this.accordionControlItems[this.selectedIndex].parentElement.classList.remove(
            this.toggleClass,
          );
          this.accordionContentItems[this.selectedIndex].style.height = '0px';
          this.accordionContentItems[controlIndex].style.height = 'auto';
          const openAccordionElement = this.accordionContentItems[this.selectedIndex].parentElement;
          const bounds = openAccordionElement.getBoundingClientRect();
          if (bounds.top < 0) {
            openAccordionElement.scrollIntoView({
              behavior: 'instant',
            });
          }
        } else {
          this.accordionControlItems[this.selectedIndex].parentElement.classList.remove(
            this.toggleClass,
          );
          const startHeight = this.accordionControlItems[this.selectedIndex].offsetHeight;
          const endHeight = 0;
          this.animateTime = animationTimeFast;
          this.animate(startHeight, endHeight, this.selectedIndex);
          this.animateTime = animationTimeDefault;
        }
      }
    }
    this.selectedIndex = controlIndex;
    if (showAnimation) this.animate(start, end, controlIndex);
  }

  togglePointerEvents = () => {
    if (this.isContentAnimating) {
      this.context.style.pointerEvents = 'none';
    } else {
      this.context.style.pointerEvents = 'auto';
    }
  }


  animate(startValue, endValue, index) {
    this.isContentAnimating = true;
    this.togglePointerEvents();
    let motionTween = this.accordionMotionTweenItems[index];

    if (motionTween !== undefined) {
      motionTween.destroy();
    }

    motionTween = new MotionTween({
      startValue,
      endValue,
      time: this.animateTime,
      update: value => this.onUpdateTween(index, value),
      complete: () => this.setActiveState(index),
      animatorType: MotionTween.animatorType.ease,
      animatorOptions: {
        easingFunction: MotionTween.easingFunction.easeInOutQuad,
      },
    }).start();

    this.accordionMotionTweenItems[index] = motionTween;
  }


  onUpdateTween(index, value) {
    this.accordionContentItems[index].style.height = `${value}px`;
  }


  setActiveState(index) {
    if (index === this.selectedIndex) {
      if (!this.isActiveContentOpen) {
        this.accordionContentItems[index].style.height = 'auto';
      } else {
        this.accordionContentItems[index].style.height = '0px';
      }
    } else {
      this.accordionContentItems[index].style.height = '0px';
    }
    this.isContentAnimating = false;
    this.togglePointerEvents();
  }
}
