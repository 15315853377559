import ViewManager from '../../../managers/view';
import ScrollToAnchorManager from '../../../managers/scroll-to-anchor';

export default class CampsiteMenu {
  static previouslySelectedNavigationItem = null;

  constructor(element) {
    this.element = element;
    this.navItemActive = null;
    this.navItemAnchors = [...document.querySelectorAll('[data-campsite-menu="anchor"]')];
    this.navigationItems = null;
    this.mobileDropdownText = this.element.querySelector('.CampsiteMenu_mobileDropdownText');
    this.init();
  }


  init() {
    const mobileNav = this.element.querySelector('[data-campsite-menu="mobile-nav"]');
    const navigation = this.element.querySelector('[data-campsite-menu="nav"]');

    let navItemHtml = '';
    this.navItemAnchors.forEach((navItemAnchor) => {
      navItemHtml += `
        <li class="CampsiteMenu_navItem" data-campsite-menu="nav-item">
          <a href="#${navItemAnchor.dataset.scrollToAnchorId}" class="CampsiteMenu_navItemLink" data-campsite-menu="nav-item-anchors">
            <div class="CampsiteMenu_navItemLinkText">${navItemAnchor.title}</div>
          </a>
        </li>
        `;
    });
    navigation.innerHTML = navItemHtml;

    this.navigationItems = [...document.querySelectorAll('[data-campsite-menu="nav-item"]')];
    this.navigationItemAnchors = [...document.querySelectorAll('[data-campsite-menu="nav-item-anchors"]')];

    this.navigationItems.forEach((navigationItem, index) => {
      navigationItem.addEventListener('click', () => {
        const navigationItemAnchorHash = this.navigationItemAnchors[index].hash;
        const urlContainsAnchorHash = window.location.hash === navigationItemAnchorHash;
        if (urlContainsAnchorHash) {
          ScrollToAnchorManager.scrollToAnchor(navigationItemAnchorHash);
        }

        this.getInViewItem(true);
      });
      if (index === 0) {
        this.mobileDropdownText.textContent = navigationItem.innerText;
      }
    });

    this.navigationItemAnchors.forEach((navigationItemAnchor) => {
      navigationItemAnchor.addEventListener('click', () => {
        if (!!window.MSInputMethodContext && !!document.documentMode) {
          ScrollToAnchorManager.scrollToAnchor(navigationItemAnchor.href.split('#').pop());
        }
      });
    });

    if (mobileNav) {
      mobileNav.addEventListener('click', () => {
        this.toggleControl(mobileNav);
      });
    }

    if (!document.body.classList.contains('ExperienceEdit')) {
      ViewManager.on(ViewManager.EVENT_UPDATE, this.onViewUpdate);
    }

    const bookButton = this.element.querySelector('[data-campsite-menu-button]');
    if (bookButton) {
      bookButton.addEventListener('click', () => {
        if (bookButton.href.indexOf('#') > -1) {
          ScrollToAnchorManager.scrollToAnchor(bookButton.href.split('#').pop());
        }
      });
    }
  }


  toggleControl = (item) => {
    if (item.classList.contains('active')) {
      item.classList.remove('active');
    } else {
      item.classList.add('active');
    }
  }


  changeActiveState(itemElement, isActive) {
    if (isActive) {
      itemElement.classList.add('active');
      this.element.classList.add('active');
      CampsiteMenu.previouslySelectedNavigationItem = itemElement;
      this.mobileDropdownText.textContent = itemElement.innerText;
    } else {
      itemElement.classList.remove('active');
      this.element.classList.remove('active');
      CampsiteMenu.previouslySelectedNavigationItem = null;
    }
  }


  deactivatePreviouslySelectedNavigationItem = () => {
    if (CampsiteMenu.previouslySelectedNavigationItem) {
      const isActive = false;
      this.changeActiveState(CampsiteMenu.previouslySelectedNavigationItem, isActive);
    }
  }


  getInViewItem = (hasClicked) => {
    const yOffset = 100;
    this.navItemAnchors.forEach((navItemAnchor) => {
      if (navItemAnchor.offsetTop - window.pageYOffset < yOffset) {
        this.navigationItems.forEach((navigationItem) => {
          if (navigationItem.innerText.indexOf(navItemAnchor.title) > -1 && this.navItemActive !== navigationItem) {
            if (!hasClicked) {
              const isActive = CampsiteMenu.previouslySelectedNavigationItem !== navigationItem;
              this.deactivatePreviouslySelectedNavigationItem();
              this.changeActiveState(navigationItem, isActive);
            }
            this.navItemActive = navigationItem;
          }
        });
      }
    });
  }


  onViewUpdate = (data) => {
    if (data.scrollUpdate) {
      const campsiteMenu = this.element;
      const stickyMenuOffset = campsiteMenu.parentElement.offsetTop;

      if (window.pageYOffset >= stickyMenuOffset) {
        campsiteMenu.classList.add('CampsiteMenu-sticky');
      } else {
        campsiteMenu.classList.remove('CampsiteMenu-sticky');
      }
      this.getInViewItem();
    }
  }
}
