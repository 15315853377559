import InViewManager from '../../managers/in-view';

export default class TwoPanelCarouselLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);
  }

  onInView = (element) => {
    if (!document.body.classList.contains('ExperienceEdit')) {
      TwoPanelCarouselLoader.load(element);
    }
  }

  static async load(element) {
    const { default: TwoPanelCarousel } = await import(/* webpackChunkName: "TwoPanelCarousel" */'../modules/TwoPanelCarousel');

    new TwoPanelCarousel(element);
  }
}
