// Function to format campsite object to what CampsiteCard expects
const sortCampsiteObject = campsite => ({
  ...campsite,
  id: campsite.siteId,
  name: campsite.title,
  url: campsite.imageUrl,
  shortDescription: campsite.summary,
  latitude: 0,
  longitude: 0,
});

export default sortCampsiteObject;
