import CampsiteCardAbstract from '../../CampsiteCardAbstract';

export const layoutPropChecker = (props, propName, componentName) => {
  if (Object.values(CampsiteCardAbstract.layoutType).indexOf(props[propName]) === -1) {
    return new Error(`Invalid prop '${propName}', '${props[propName]}' supplied to '${componentName}', should be set to one of ${Object.values(CampsiteCardAbstract.layoutType).toString()} Validation failed.`);
  }
  return null;
};

export default null;
