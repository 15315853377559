export default class LinkScraper {
  constructor(context) {
    this.context = context;
    this.initLinkScraper();
  }

  initLinkScraper() {
    const links = [...this.context.querySelectorAll('a, button')];

    // when viewing a basic version of a campsite page, disable all external links
    links.forEach((link) => {
      if (link.hostname !== '' && link.hostname !== window.location.hostname) {
        link.addEventListener('click', this.disableLink);
        if (link.tagName.toLowerCase() === 'a') {
          this.removeLinkStyles(link);
        }
      }
    });
  }

  disableLink = (event) => {
    event.preventDefault();
  }

  removeLinkStyles = (linkElement) => {
    linkElement.style.textDecoration = 'none';
    linkElement.classList.add('disable-link');
  }
}
