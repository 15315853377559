import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

const Icon = ({ className, name }) => {
  const classString = ClassNames({
    Icon: true,
    [className]: true,
  });

  const htmlElement = document.querySelector('html');

  const { isnewbrandingenabled: isNewBrandingEnabled } = htmlElement.dataset;

  const rebrandEnabled = isNewBrandingEnabled
    ? isNewBrandingEnabled.toLowerCase() === 'true'
    : false;

  const iconSheet = rebrandEnabled ? '/dist/icons/rebrand/icons.svg' : '/dist/icons/icons.svg';

  return (
    <>
      {!!name &&
        <svg className={classString} viewBox="0 0 36 36" aria-hidden="true">
          <use xlinkHref={`${iconSheet}#${name}`} />
        </svg>
      }
    </>
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
