import React from 'react';
import PropTypes from 'prop-types';
import PrimaryCampsite from './PrimaryCampsite';
import AdditionalCampsites from './AdditionalCampsites';

import sortCampsiteObject from '../helpers/sortCampsiteObject';

const Campsites = ({ model, campsites, facets }) => {
  const additionalCampsitesFormatted =
    campsites.relatedCampsites.map(site => sortCampsiteObject(site));
  return (
    <div className="Campsites">
      <div className="Campsites_siteContainer Campsites_top">
        <PrimaryCampsite
          model={model}
          campsite={sortCampsiteObject(campsites.primaryCampsite)}
          facets={facets}
        />
      </div>
      {campsites.relatedCampsites.length > 0 && (
        <div className="Campsites_siteContainer Campsites_lower">
          <AdditionalCampsites
            model={model}
            campsiteData={additionalCampsitesFormatted}
            facets={facets}
          />
        </div>
      )}
    </div>
  );
};

Campsites.propTypes = {
  model: PropTypes.object.isRequired,
  campsites: PropTypes.object,
  facets: PropTypes.object.isRequired,
};

export default Campsites;
