export function closest(element, className) {
  const parent = element.parentElement;
  if (parent) {
    if (parent.classList.contains(className)) {
      return parent;
    }
    return closest(parent, className);
  }
  return null;
}


export function parents(element, selector) {
  const matchingParents = [];
  // we move to parent so selector is not applied to itself
  element = element.parentElement;

  while (element.parentElement) {
    if ([...element.parentElement.querySelectorAll(selector)].indexOf(element) > -1) {
      matchingParents.push(element);
    }

    element = element.parentElement;
  }

  return matchingParents;
}


export function insertAfter(newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}


export function empty(node) {
  while (node.firstChild) {
    node.removeChild(node.firstChild);
  }
}


export function remove(node) {
  node.parentNode.removeChild(node);
}


export function getParentBeforeBody(element) {
  // iterate over parent nodes, to get scroll container
  let parentContainer = element.parentNode;
  while (parentContainer.parentNode !== document.body) {
    parentContainer = parentContainer.parentNode;
  }

  return parentContainer;
}
