/**
 * MTI Font tracking distilled and integrated into app, more info at
 * Graphics/Camping & Caravanning Club/Assets/Fonts/MTI-WebFonts-1162420558
 */
const userId = '2b9870b4-bf50-40b8-b8d2-143d881e4547';
const pf = '1475948'; // Univers® W04 45 Light
const mtTracking = document.createElement('link');
mtTracking.type = 'text/css';
mtTracking.rel = 'preload';
mtTracking.href = `//fast.fonts.net/lt/1.css?apiType=css&c=${userId}&fontids=${pf}`;
document.getElementsByTagName('body')[0].appendChild(mtTracking);

/**
* MyFonts Font integrated and added as above for consistency into app, more info at
* Graphics/Camping & Caravanning Club/Assets/Fonts/MyFontsWebfontsKit
*/
const myFontsTracking = document.createElement('link');
myFontsTracking.type = 'text/css';
myFontsTracking.rel = 'preload';
myFontsTracking.href = '//hello.myfonts.net/count/37cb21';
document.getElementsByTagName('body')[0].appendChild(myFontsTracking);
