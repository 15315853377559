import Utils from './Utils';

// The pixel size between image requests made to save server stress
const imageGranularity = 64;

const IMAGE_STATE = {
  loading: 'loading',
  loaded: 'loaded',
  error: 'error',
  initialised: 'initialised',
};


function destroy(image) {
  image.onLoad = null;
  image.onError = null;
}


function load(src, callback) {
  let image = new Image();

  image.onload = () => {
    destroy(image);
    image = null;
    callback(null);
  };

  image.onerror = () => {
    destroy(image);
    image = null;
    callback(IMAGE_STATE.error);
  };

  image.src = src;
}


export function updateImageSrcString(imageSrc, width, height, useMax) {
  if (useMax === undefined) {
    useMax = false;
  }

  let src = Utils.updateQueryStringParameter(imageSrc, useMax ? 'mw' : 'w', width);
  src = Utils.updateQueryStringParameter(src, useMax ? 'mh' : 'h', height);

  return src;
}


export function loadImageBackground(src, callback) {
  const payload = {
    src,
    loadState: IMAGE_STATE.loaded,
  };

  load(src, (error) => {
    const stateString = error !== null ? IMAGE_STATE.error : IMAGE_STATE.loaded;
    payload.loadState = stateString;

    callback(payload);
  });
}

export function getBoundsData(element) {
  // Test image is within viewport range
  const bounds = element.getBoundingClientRect();

  const width = Math.ceil(bounds.width / imageGranularity) * imageGranularity;
  const height = Math.ceil(bounds.height / imageGranularity) * imageGranularity;

  return {
    width,
    height,
    isWithinBounds:
      bounds.height > 0
      && bounds.width > 0
      && bounds.top < (window.innerHeight + 200)
      && bounds.bottom > -200,
  };
}


export function isStillLazy(element) {
  return !element.classList.contains('LazyImage--loaded')
  && !element.classList.contains('LazyImage--loading');
}
