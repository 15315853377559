import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { handleScroll, handleWindowChange } from '../helpers/eventListeners';

const priceFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });

const DateCard = ({ date, model, showTop, isExtra }) => {
  const [showFixedDates, setShowFixedDates] = useState(false);
  const longDate = dayjs(date.date).format('D MMMM (ddd)');
  const shortDate = dayjs(date.date).format('D MMM (ddd)');
  const handleScrollCaller = () => {
    handleScroll(setShowFixedDates);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollCaller);
    window.addEventListener('resize', handleWindowChange);
    handleWindowChange();
    return () => {
      // cleanup to prevent memory leak
      window.removeEventListener('scroll', handleScrollCaller);
      window.removeEventListener('resize', handleWindowChange);
    };
  }, []);

  const getButtonMessage = (isBookable, inSeason) => {
    if (isBookable) return model.bookButtonLabel;
    if (inSeason) return model.soldOutLabel;
    return model.unavailableLabel;
  };

  return (
    <div className="DateCard_dateCol">
      {showTop && (
        <>
          <div className="DateCard_upper">
            <div className="DateCard_fullDate">
            {!isExtra && longDate}
            </div>
            <div className="DateCard_shortDate">
            {!isExtra && shortDate}
            </div>
          </div>
          {!isExtra && (
            <div className={`DateCard_upper-fixed ${showFixedDates ? 'DateCard_upper-show' : ''}`}>
              {!isExtra && longDate}
            </div>
          )}
        </>
      )}
      <div className="DateCard_lower">
        <div className="DateCard_cardTop">
          <span className="DateCard_nights">
            {!isExtra
              ? model.nightsTitleFormat.replace('{0}', date && date.nights)
              : 'View more dates'}
          </span>
        </div>
        <div className="DateCard_cardBottom">
          <span className="DateCard_special">
            {!isExtra &&
              date &&
              date.stock > 0 &&
              date.stock <= model.lowStockValue &&
              model.remainingAvailabilityFormat.replace('{0}', date && date.stock)}
          </span>
          <span className="DateCard_price">
            {!isExtra && date.isBookable && <span>{`${date && priceFormat.format(date.price)}`}</span>}
          </span>
          {!isExtra && (
            <a
              href={date && date.isBookable ? date.bookUrl : window.location.href}
              className={`DateCard_submit DateCard_submit-${
                date && date.isBookable ? 'bookNow' : 'soldOut'
              }`}>
              {date && getButtonMessage(date.isBookable, date.isInSeason)}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

DateCard.propTypes = {
  date: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  showTop: PropTypes.bool,
  isExtra: PropTypes.bool,
};

DateCard.defaultProps = {
  showTop: true,
  isExtra: false,
};

export default DateCard;
