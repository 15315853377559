import InViewManager from '../../managers/in-view';

export default class JraAddComponentLoader {
  constructor(element) {
    if (!document.body.classList.contains('ExperienceEdit')) {
      InViewManager.add(element, this.onInView);
    } else {
      this.onInView(element);
    }
  }

  onInView = (element) => {
    JraAddComponentLoader.load(element);
  }

  static async load(element) {
    const { default: JraAddComponent } = await import(/* webpackChunkName: "JraAddComponent" */'../modules/JraForm/JraAddComponent');

    new JraAddComponent(element);
  }
}
