import ViewManager from '../../../managers/view';

export default class CampsiteTop {
  constructor(context) {
    this.context = context;

    this.button = this.context.querySelector('[data-campsite-top-button]');
    this.activeToggle = this.context.querySelector('[data-campsite-top-active-toggle]');
    this.toggleClass = 'active';

    this.init();
  }

  init() {
    if (this.button) {
      this.button.addEventListener('click', () => this.toggleControl(this.activeToggle));
    }
  }

  toggleControl(activeToggle) {
    if (activeToggle.classList.contains(this.toggleClass)) {
      activeToggle.classList.remove(this.toggleClass);
    } else {
      activeToggle.classList.add(this.toggleClass);
      // used in order to load the map on click of the mobile toggle button.
      ViewManager.invalidate();
    }
  }
}
