import React, { Fragment } from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

import CampsiteCardAbstract from '../../CampsiteCardAbstract';
import LazyImage from '../../LazyImage';
import Icon from '../../Icon';
import { resolveFacilityIconClass } from '../../../../util/campsite-data';
import { campsiteDataPropTypes, facetTypesPropTypes } from '../../prop-types';
import { layoutPropChecker } from '../../SiteSeeker/helpers/campsiteCardHelpers';
import DOMPurify from 'isomorphic-dompurify';

class CampsiteCard extends CampsiteCardAbstract {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.campsiteData.id !== nextProps.campsiteData.id
    ) {
      return true;
    }

    return false;
  }

  renderIsMembersOnlyBanner(labels, popOutParentContainerSelector) {
    return (
      <h6 className="CampsiteCard_isMemberOnlySite" ref={this.getPopOutInstance('memberOnlySitePopout').updateReactRef} data-pop-out="control" data-pop-out-parent-container-selector={popOutParentContainerSelector}>
        <span data-pop-out="hit-trigger">
          <div className="PopOut PopOut-darkAqua" data-pop-out="container">
            <div className="PopOut_content">{labels.membersOnlySiteInfo}</div>
          </div>
          {labels.membersOnlySite}
          <div className="Form_popOutHit Form_popOutHit-inline Form_popOutHit-campsiteCardAquaBack" data-pop-out="hit">i</div>
        </span>
      </h6>
    )
  }

  render() {
    const {
      campsiteData,
      facetTypes,
      labels,
      layoutType,
      settings,
      showSubmit,
      isClickable,
      showReviews,
    } = this.props;

    // const is = layoutType === CampsiteCard.layoutType.SITE_SEEKER_FAVOURITES;
    const isMapLayout = layoutType === CampsiteCard.layoutType.MAP;
    const isRegionMapLayout = layoutType === CampsiteCard.layoutType.REGIONS_MAP;

    if (__DEVELOPMENT__) {
      console.log('CampsiteCard render', campsiteData.id);
    }

    const overallRating = campsiteData.overallRating * 2;
    let imageUrl = '';

    // ensure data has actually loaded
    if (campsiteData.url) {
      imageUrl = campsiteData.imageUrl || settings.defaultCampsiteImageUrl;
    }

    const classes = ClassNames({
      CampsiteCard: true,
      [`CampsiteCard-${layoutType}`]: true,
      'CampsiteCard-loading': campsiteData.id === undefined,
    });

    let url = '';

    if (campsiteData.id) {
      url = campsiteData.url;
    }

    let popOutParentContainerSelector;
    if (isMapLayout || isRegionMapLayout) {
      popOutParentContainerSelector = 'Map_containerElement';
    }

    const facilityIconsData = resolveFacilityIconClass(campsiteData.facilities, facetTypes);

    return (
      <div className={classes}>
        <div className="CampsiteCard_imageCol">

          {isClickable
            ? <a className="CampsiteCard_imageLink" href={isClickable && campsiteData.url}>
                <LazyImage src={imageUrl} className="CampsiteCard_image"></LazyImage>
              </a>
            : <LazyImage src={imageUrl} className="CampsiteCard_image"></LazyImage>
          }

          <div className="CampsiteCard_banners">
            {campsiteData.isMembersOnly &&
              this.renderIsMembersOnlyBanner(labels, popOutParentContainerSelector)
            }
          </div>
        </div>
        <div className="CampsiteCard_detailsCol">
          <Fragment>
            <div className="CampsiteCard_topContent">
              <header className="CampsiteCard_header">
                {campsiteData.id &&
                <>
                  {isClickable
                    ? <a href={url} className="CampsiteCard_nameLink">
                        <h5 className="CampsiteCard_name">{campsiteData.name}</h5>
                      </a>
                    : <h5 className="CampsiteCard_name">{campsiteData.name}</h5>
                  }
                </>
                }
              </header>

              <div className="CampsiteCard_details">
                {campsiteData.id &&
                  <div className="CampsiteCard_infoItems">
                    <div className="CampsiteCard_infoItem">
                      <h6 className="CampsiteCard_text">{campsiteData.location}</h6>
                    </div>
                  </div>
                }
              </div>

              {isRegionMapLayout && campsiteData.isMembersOnly &&
                this.renderIsMembersOnlyBanner(labels, popOutParentContainerSelector)
              }

              {campsiteData.reviewCount > 0 && showReviews &&
                <>
                {isClickable
                  ? <a className="CampsiteCard_reviewSummary ReviewSummary" href={`${url}/#reviews`}>
                      <div className={`ReviewSummary_starRating Rating Rating-small Rating-${overallRating}`}></div>
                      <div className="ReviewSummary_text">
                        <span className="ReviewSummary_reviewCount">({campsiteData.reviewCount})</span>
                      </div>
                    </a>
                  : <div className="CampsiteCard_reviewSummary ReviewSummary ReviewSummary-alt">
                      <div className={`ReviewSummary_starRating Rating Rating-small Rating-${overallRating}`}></div>
                      <div className="ReviewSummary_text">
                        <span className="ReviewSummary-alt ReviewSummary_reviewCount">({campsiteData.reviewCount})</span>
                      </div>
                    </div>
                }
              </>
              }
              {campsiteData.shortDescription &&
                <h6 className="CampsiteCard_shortDescription" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(campsiteData.shortDescription) }}></h6>
              }
            </div>

            <footer className="CampsiteCard_footerContent">
              <div className="CampsiteCard_facilitiesContent">
              {facilityIconsData.length > 0 && <h6 className="CampsiteCard_facilitiesLabel">{labels.keyFacilities}</h6>}
                <ul className="CampsiteCard_facilities">
                  {facilityIconsData.map((keyFacilityIconData, index) => {
                    if (index >= CampsiteCard.MAX_FACILITIES) return null;
                    return (
                      <li key={index} className="CampsiteCard_facility" ref={this.getPopOutInstance(`facilitiesPopout${index}`).updateReactRef} data-pop-out="control" data-pop-out-parent-container-selector={popOutParentContainerSelector}>
                        <div data-pop-out="hit-trigger">
                          <div className="PopOut" data-pop-out="container">
                            <div className="PopOut_content" dangerouslySetInnerHTML={{ __html: keyFacilityIconData.infoPopout }}></div>
                          </div>
                          <Icon className="CampsiteCard_facilityIcon" name={keyFacilityIconData.iconClass} data-pop-out="hit" />
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {showSubmit &&
                <div className="CampsiteCard_buttonRow">
                  <a href={url} className="CampsiteCard_button Button">
                    {labels.cardViewButton}
                  </a>
                </div>
              }

            </footer>
          </Fragment>
        </div>
      </div>
    );
  }
}

CampsiteCard.propTypes = {
  campsiteData: campsiteDataPropTypes,
  facetTypes: facetTypesPropTypes,
  labels: PropTypes.object,
  layoutType: layoutPropChecker,
  settings: PropTypes.object,
  showSubmit: PropTypes.bool,
  isClickable: PropTypes.bool,
  showReviews: PropTypes.bool,
};

CampsiteCard.defaultProps = {
  showSubmit: true,
  isClickable: true,
  showReviews: true,
};


export default CampsiteCard;
