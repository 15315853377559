export default class InputCounter {
  constructor(element) {
    this.element = element;

    this.span = null;
    this.maxLength = 0;
    this.warningLength = 10;

    this.init();
  }


  init() {
    this.span = this.element.querySelector('[data-input-counter-counter]');
    this.input = this.element.querySelector('[data-input-counter-input]');

    this.input.addEventListener('keyup', this.onUpdate);
    this.input.addEventListener('change', this.onUpdate);

    this.maxLength = this.input.getAttribute('maxlength');
    if (this.maxLength) {
      this.maxLength = parseInt(this.maxLength, 10);
    } else {
      this.span.style.display = 'none';
    }

    this.onUpdate();
  }


  onUpdate = () => {
    this.span.innerHTML = this.maxLength - this.input.value.length;
    if (this.input.value.length >= (this.maxLength - this.warningLength)) {
      this.span.classList.add('warning');
    } else {
      this.span.classList.remove('warning');
    }
  }
}
