import { getCookie, setCookie } from '../../../util/cookies';


export default class GtmDataLayer {
  constructor(context) {
    this.context = context;
    this.init();
  }

  init() {
    window.dataLayer = window.dataLayer || [];
    const model = JSON.parse(this.context.dataset.model);
    const renewalDate = this.context.dataset.renewalDate;
    model.RenewalDate = renewalDate;
    const isAuthenticated = model.IsAuthenticated;
    this.loggedInCookieName = 'logged_in';
    const loggedInCookie = getCookie(this.loggedInCookieName);

    if (isAuthenticated && model && model.Number) {
      this.pushLoggedInPageViewDataLayerObject(model.Number);
    }

    if (isAuthenticated && !loggedInCookie) {
      this.pushLoginDataLayerObject(model);
    } else if (!isAuthenticated && loggedInCookie) {
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() - 10);
      const expiryDateString = expiryDate.toUTCString();
      setCookie(this.loggedInCookieName, true, expiryDateString);
    }
  }

  pushLoginDataLayerObject = (data) => {
    const dataLayerObject = {
      acr: data.IsACR,
      event: 'log_in',
      family: data.IsFamily,
      loggedIn: true,
      membershipNumber: data.Number,
      membershipPaymentMethod: data.PaymentType,
      memberStatus: data.Status,
      renewalDate: data.RenewalDate,
      type: data.Type,
      uniqueId: data.UniqueId,
      unit: data.Unit,
      userId: data.Number,
    };
    window.dataLayer.push(dataLayerObject);
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 1);
    const expiryDateString = expiryDate.toUTCString();
    setCookie(this.loggedInCookieName, true, expiryDateString);
  }

  pushLoggedInPageViewDataLayerObject = (membershipNumber) => {
    window.dataLayer.push({
      event: 'page_view',
      membershipNumber,
    });
  }
}
