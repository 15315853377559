export default class TravelDirectionsModal {
    constructor(context) {
      this.context = context;
      this.initTravelDirectionsModal();
    }
  
    initTravelDirectionsModal() {
      this.toggleClass = 'TravelDirectionsModal-active';
      this.control = this.context.querySelector('[data-travel-directions-modal-control]')
      this.modal = this.context.querySelector('[data-travel-directions-modal]');
      this.close = this.context.querySelector('[data-travel-directions-modal-close]');

      this.control.addEventListener('click', this.toggleDisplay);
      this.close.addEventListener('click', this.toggleDisplay);
    }

    toggleDisplay = () => {
        if(this.modal.classList.contains(this.toggleClass)) {
            this.modal.classList.remove(this.toggleClass);
            document.documentElement.classList.remove('scroll-lock');
        }
        else {
            this.modal.classList.add(this.toggleClass);
            document.documentElement.classList.add('scroll-lock');
        }
    }
}
  