export default class Rectangle {
  scale = 1;

  left = 0;

  top = 0;

  width = 0;

  height = 0;

  right = 0;

  bottom = 0;


  constructor(left, top, width, height) {
    this.left = left || 0;
    this.top = top || 0;
    this.width = width || 0;
    this.height = height || 0;
    this.updateBottomAndRight();
  }


  setX(value) {
    this.left = value;
  }


  setY(value) {
    this.top = value;
  }


  setLeft(value) {
    this.left = value;
  }


  setTop(value) {
    this.top = value;
  }


  setWidth(value) {
    this.width = value;
  }


  setHeight(value) {
    this.height = value;
  }

  setRight(value) {
    this.width = value - this.left;
    this.updateBottomAndRight();
  }


  setBottom(value) {
    this.height = value - this.top;
    this.updateBottomAndRight();
  }


  contains(x, y) {
    if (
      x >= this.left
      && x < this.left + this.width
      && y >= this.top
      && y < this.top + this.height
    ) {
      return true;
    }
    return false;
  }


  containsRect(x, y, w, h) {
    if (
      x >= this.left
      && (x + w) <= this.left + this.width
      && y >= this.top
      && (y + h) <= this.top + this.height
    ) {
      return true;
    }
    return false;
  }


  intersects(rect) {
    return !(
      rect.left > this.right
      || rect.right < this.left
      || rect.top > this.bottom
      || rect.bottom < this.top
    );
  }


  union(toUnion) {
    const union = new Rectangle();
    union.left = Math.min(this.left, toUnion.left);
    union.top = Math.min(this.top, toUnion.top);
    union.width = Math.max(this.left + this.width, toUnion.left + toUnion.width) - union.left;
    union.height = Math.max(this.top + this.height, toUnion.top + toUnion.height) - union.top;
    return union;
  }


  toString() {
    return `Rectangle left:${this.left} top:${this.top} width:${this.width} height:${this.height}`;
  }


  applyScale(scale) {
    this.left *= scale;
    this.top *= scale;
    this.width *= scale;
    this.height *= scale;
  }


  updateBottomAndRight() {
    this.bottom = this.top + this.height;
    this.right = this.left + this.width;
  }


  static intersect(rect1, rect2) {
    const rectI = new Rectangle();
    rectI.left = Math.max(rect1.left, rect2.left);
    rectI.top = Math.max(rect1.top, rect2.top);
    rectI.right = Math.min(rect1.right, rect2.right);
    rectI.bottom = Math.min(rect1.bottom, rect2.bottom);
    return rectI;
  }
}
