import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

export default class Availability {
  constructor(context) {
    this.context = context;
    this.root = createRoot(context);
    this.element = document.querySelector('[data-component="Availability"]');

    this.initAvail();
  }

  initAvail() {
    this.render(
      this.element.dataset.apiBaseurl,
      this.element.dataset.dateDurationRules,
      this.element.dataset.seasonRules,
      this.element.dataset.model,
      this.element.dataset.campsites,
      this.element.dataset.regions,
      this.element.dataset.facets,
    );
  }

  render(endpoint, durationRules, seasonRules, model, campsites, regions, facets) {
    this.root.render(
      <App
        endpoint={endpoint}
        durationRules={durationRules}
        seasonRules={seasonRules}
        model={JSON.parse(model)}
        campsites={JSON.parse(campsites)}
        regions={JSON.parse(regions)}
        facets={JSON.parse(facets)}
      />,
    );
  }
}
