import InViewManager from '../../managers/in-view';

export default class ReviewsLoader {
  constructor(element) {
    if (!document.body.classList.contains('ExperienceEdit')) {
      InViewManager.add(element, this.onInView);
    }
  }

  onInView = (element) => {
    ReviewsLoader.load(element);
  }

  static async load(element) {
    const { default: Reviews } = await import(/* webpackChunkName: "Reviews" */'../render/Reviews');

    new Reviews(element);
  }
}
