import React from 'react';
import PropTypes from 'prop-types';
import Pikaday from 'pikaday';

import Icon from '../Icon';
import Form from '../../modules/Form';

import { format } from '../../../constants/dates';
import { isValidDateObject } from '../../../util/date';

class DatePicker extends React.Component {
  pikaday = undefined;

  inputRef = React.createRef();

  destroy() {
    if (this.pikaday) {
      this.disabledObserver.disconnect();
      this.pikaday.destroy();
    }
  }

  static onBlur(event) {
    Form.onInputBlur(event);
  }

  static onFocus(event) {
    Form.onInputFocus(event);
  }

  initPikaday() {
    this.destroy();
    this.pikaday = new Pikaday({
      defaultDate: this.props.date,
      setDefaultDate: true,
      format,
      field: this.inputRef.current,
      minDate: new Date(),
      disableDayFn: this.props.disableDayFn,
      onSelect: this.onPikadaySelectDate.bind(this),
    });
  }

  onPikadaySelectDate(date) {
    if (this.props.date && date && this.props.date.getDate() === date.getDate()) return;
    this.props.onChange(date);
  }

  componentDidMount() {
    this.disabledObserver
      = Form.createInputDisabledMutationObserver(this.inputRef.current);

    this.initPikaday();
  }

  componentDidUpdate() {
    if (this.props.disabled) return;

    this.pikaday.setDate(isValidDateObject(this.props.date) ? this.props.date : null);

    if (isValidDateObject(this.props.date)) {
      this.pikaday.gotoDate(this.props.date);
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  render() {
    const {
      disabled,
      placeholder,
      id,
      label,
      name,
      parentClass,
      validationMessage,
      isValid,
    } = this.props;

    return (
      <div className={`${parentClass}_control`} data-form="control">
        <div className={`${parentClass}_controlCols Form_controlCols`}>
          <div className={`${parentClass}_controlCol Form_controlCol`}>
            <Icon name="Date" className="Form_inlineIcon" />
            <input
              className={`${parentClass}_text Form_text ${parentClass}_text-datePicker Form_text-datePicker`}
              disabled={disabled}
              name={name}
              id={id}
              type="text"
              readOnly
              placeholder={placeholder}
              data-form="input"
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              ref={this.inputRef}
              ></input>
            <label className={`${parentClass}_controlLabel Form_controlLabel`} htmlFor={id}>{placeholder}</label>
          </div>
        </div>
        <legend className={`${parentClass}_controlLabel`}>{label}</legend>
        {!isValid && <span className={`${parentClass}_validation`}>{validationMessage}</span>}
      </div>
    );
  }
}

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disableDayFn: PropTypes.func,
  name: PropTypes.string,
  parentClass: PropTypes.string,
  validationMessage: PropTypes.string,
  isValid: PropTypes.bool,
};

export default DatePicker;
