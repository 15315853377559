import ScrollToAnchorManager from '../../../managers/scroll-to-anchor';

export default class ScrollToAnchor {
  constructor(anchor) {
    this.anchor = anchor;

    anchor.addEventListener('click', () => {
      const hash = anchor.href.split('#').pop();
      if (hash && !!window.MSInputMethodContext && !!document.documentMode) {
        ScrollToAnchorManager.scrollToAnchor(hash);
      }
    });
  }
}
