import React from 'react';

import PopOut from '../../modules/PopOut';

class CampsiteCardAbstract extends React.Component {
  static layoutType = {
    MAP: 'map',
    REGIONS: 'regions',
    REGIONS_MAP: 'regionsMap',
    SITE_SEEKER_FAVOURITES_LISTING: 'siteSeekerFavouritesListing',
    SITE_SEEKER_LISTING: 'siteSeekerListing',
  }

  static MAX_FACILITIES = 8;

  constructor(props) {
    super(props);

    this.popOutInstances = new Map();
  }

  componentWillUnmount() {
    this.popOutInstances.forEach(popout => popout.destroy());
    this.popOutInstances.clear();
  }

  getPopOutInstance(key) {
    if (!this.popOutInstances.has(key)) {
      this.popOutInstances.set(key, new PopOut());
    }

    return this.popOutInstances.get(key);
  }
}

export default CampsiteCardAbstract;
