import InViewManager from '../../managers/in-view';

export default class JraFormLoader {
  constructor(element) {
    if (!document.body.classList.contains('ExperienceEdit')) {
      InViewManager.add(element, this.onInView);
    } else {
      this.onInView(element);
    }
  }

  onInView = (element) => {
    JraFormLoader.load(element);
  }

  static async load(element) {
    const { default: JraForm } = await import(/* webpackChunkName: "JraForm" */'../modules/JraForm');

    new JraForm(element);
  }
}
