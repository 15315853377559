function deg2rad(deg) {
  return deg * (Math.PI / 180);
}


function getDistanceFromLatLonInMiles(lat1, lon1, lat2, lon2) {
  const radius = 3959; // radius of the earth in miles
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = radius * c;
  return distance.toFixed(1);
}


export function getDistance(campsiteData, centerLatitude, centerLongitude) {
  if (!centerLatitude || !centerLongitude) {
    return 0;
  }

  return getDistanceFromLatLonInMiles(
    campsiteData.latitude,
    campsiteData.longitude,
    centerLatitude,
    centerLongitude,
  );
}


export function resolveCampsiteTypeData(campsiteData, facetTypes) {
  let campsiteTypeData = facetTypes.campsiteTypes.find(typeData => (
    typeData.id === campsiteData.type
  ));

  if (!campsiteTypeData) {
    campsiteTypeData = {
      name: 'Other Site',
      infoPopout: 'An unrecognised site type',
    };
  }

  return campsiteTypeData;
}

export function findFacilityIconData(id, facilityGroups) {
  for (let g = 0; g < facilityGroups.length; g += 1) {
    const facilityGroup = facilityGroups[g];
    for (let f = 0; f < facilityGroup.facets.length; f += 1) {
      const facility = facilityGroup.facets[f];
      if (id === facility.id) {
        return facility;
      }
    }
  }

  return null;
}

export function resolveFacilityIconClass(facilities, facetTypes) {
  if (facilities === null || facetTypes === null) return '';
  return facilities.reduce((facilityIconNames, id) => {
    if (!Object.prototype.hasOwnProperty.call(facetTypes, 'facilities')) return '';
    const facilityIconData = findFacilityIconData(id, facetTypes.facilities);
    if (facilityIconData) {
      facilityIconNames.push(facilityIconData);
    }
    return facilityIconNames;
  }, []);
}
