// API endpoints
export const API_TELL_US_MORE_DROP_DOWN_DATA = '/sitecore/api/ssc/Sitecore_InternalAPI_WebAPI_Controllers/JRAAjax/1/TellUsMoreOptions';

export const API_TELL_US_MORE_GIFTS_DATA = '/sitecore/api/ssc/Sitecore_InternalAPI_WebAPI_Controllers/JRAAjax/1/GetOptions';

export const API_DOES_MEMBERSHIP_NUMBER_EXIST = '/sitecore/api/ssc/Sitecore_InternalAPI_WebAPI_Controllers/JRAAjax/1/MembershipExistsByNumber?membershipnumber=#{1}';

export const API_DOES_MEMBERSHIP_EMAIL_EXIST = '/sitecore/api/ssc/Sitecore_InternalAPI_WebAPI_Controllers/JRAAjax/1/MembershipExistsByEmail?emailaddress=#{1}';

// class names
export const SECTION_EXPANDED_CLASS = 'expanded';

export const ADD_ITEMS_CONTROL_HIDDEN_CLASS = 'JraAddItem_addButton-hide';

export const OVERSEAS_CLASS = 'JraForm-overseas';

export const LOCATION_UK = 'UK';

// membership status values
export const MEMBERSHIP_STATUS_PAID = 'Paid';

export const MEMBERSHIP_STATUS_DD_PENDING = 'DD Pending';

export const SESSION_FORM_DATA_KEY = 'jra-session-form-data-key';

export const SESSION_FORM_DATA_VERSION = '1.0.0';

export const moduleTypes = {
  ASSOCIATE: 'associate',
  CAMPING_UNIT: 'campingUnit',
  CHILDREN: 'children',
  CONTACT: 'contact',
  CUSTOMISE: 'customise',
  MEMBERSHIP: 'membership',
  FORMAT: 'format',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TELL_US_MORE: 'tellUsMore',
};

export const encodeDecodeTypes = {
  FORM_INPUT: 'formInput',
  EXPANDABLE: 'expandable',
};
