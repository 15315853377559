import React from 'react';
import PropTypes from 'prop-types';

import DateCard from './DateCard';

const DateGrid = ({ dates, model, showTop, className }) => {
  return (
    <div className={`DateGrid ${className}`}>
      {dates.map((date) => {
        return (
          <DateCard
            showTop={showTop}
            key={date.date}
            date={date}
            model={model}
          />
        );
      })}
    </div>
  );
};

DateGrid.propTypes = {
  dates: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
  showTop: PropTypes.bool,
  className: PropTypes.string,
};

DateGrid.defaultProps = {
  showTop: true,
  className: '',
};

export default DateGrid;
