import InViewManager from '../../managers/in-view';

export default class SelfCateringBookingWidgetLoader {
  constructor(element) {
    InViewManager.add(element, this.onInView);
  }

  onInView = (element) => {
    if (!document.body.classList.contains('ExperienceEdit')) {
      SelfCateringBookingWidgetLoader.load(element);
    }
  }

  static async load(element) {
    const { default: SelfCateringBookingWidget } = await import(/* webpackChunkName: "SelfCateringBookingWidget" */'../modules/BookingWidget/SelfCateringBookingWidget');

    new SelfCateringBookingWidget(element);
  }
}
