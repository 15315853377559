import EventListener from 'wolfy87-eventemitter';

import { parents } from '../../../util/dom';

export default class Form extends EventListener {
  constructor(element) {
    super();

    this.element = element;

    this.form = undefined;
    this.submit = undefined;
    this.inputs = [];
    this.passwordToggles = [];
    this.formInputDisabledObservers = new Map();
    this.formControlValidationObservers = new Map();

    this.initForm();
  }


  initForm() {
    this.bindEventListeners();
    this.inputs = [...this.element.querySelectorAll('[data-form*="input"]')];

    if (__DEVELOPMENT__) {
      this.inputs.forEach(i => console.log('input:', i.id, i.nodeName, i));
    }

    this.initHeavyHandedInputFocus();

    this.form = this.element.dataset.form ? this.element : this.element.querySelector('[data-form="form"]');
    if (this.form) {
      // prevent the browser from showing the HTML5 default error bubble/ hint
      this.form.addEventListener('invalid', Form.onFormInvalid, true);

      this.submit = this.element.querySelector('[data-form="submit"]');
      if (this.submit) {
        this.submit.addEventListener('click', this.onFormSubmit);
      } else if (__DEVELOPMENT__) {
        console.warn('Form: There is no submit button that has the data-form="submit" attribute');
      }
    }

    this.initPasswordToggles();
  }


  bindEventListeners() {
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }


  destroy() {
    if (this.inputs.length) {
      this.inputs.forEach((input) => {
        input.removeEventListener('invalid', Form.onInputInvalid);
        input.removeEventListener('focus', Form.onInputFocus);
        input.removeEventListener('blur', Form.onInputBlur);
        input.removeEventListener('input', Form.onInputChange);
        input.removeEventListener('change', Form.onInputChange);
      });
    }

    if (this.passwordToggles.length) {
      this.passwordToggles.forEach(toggle => toggle.removeEventListener('click', Form.onPasswordInputToggle));
    }

    if (this.form) {
      this.form.removeEventListener('invalid', Form.onFormInvalid);
    }

    if (this.submit) {
      this.submit.removeEventListener('click', this.onFormSubmit);
    }

    if (this.formInputDisabledObservers.size) {
      this.formInputDisabledObservers.forEach(value => value.disconnect());
    }

    if (this.formControlValidationObservers.size) {
      this.formControlValidationObservers.forEach(value => value.disconnect());
    }
  }


  static onFormInvalid(event) {
    event.preventDefault();
  }


  onFormSubmit(event) {
    this.form.classList.add('validated');
    if (!this.isValid()) {
      let errorElement = this.form.querySelector('[data-form*="input"]:invalid');
      const parentsArray = parents(errorElement, '[data-form="control"]');
      if (parentsArray.length > 0) {
        errorElement = parentsArray.pop();
      }
      errorElement.scrollIntoView({ behaviour: 'smooth' });
      event.preventDefault();
    }
  }


  static onInputInvalid({ currentTarget }) {
    const formControl = Form.getFormControlFromInput(currentTarget);
    formControl.classList.add('error');
  }


  static onInputBlur({ currentTarget }) {
    const formControl = Form.getFormControlFromInput(currentTarget);
    Form.checkInputValue(currentTarget, formControl);
    formControl.classList.remove('focus');
  }


  static onInputFocus({ currentTarget }) {
    const formControl = Form.getFormControlFromInput(currentTarget);
    formControl.classList.add('focus');
  }


  static onInputChange({ currentTarget }) {
    const formControl = Form.getFormControlFromInput(currentTarget);
    // we use a timeout to ensure the input event stack is complete and therefore
    // any other validation logic has occured. We then follow up checking for a
    // validation error.
    setTimeout(() => {
      if (currentTarget.checkValidity()) {
        formControl.classList.remove('error');
      } else {
        formControl.classList.add('error');
      }
    }, 0);
  }


  static onPasswordInputToggle(event) {
    const toggle = event.currentTarget;
    const passwordInput = toggle.parentNode.querySelector('[data-form*="password-input"]');

    const isHidden = passwordInput.getAttribute('type') === 'password';
    if (isHidden) {
      toggle.classList.add('Form_passwordToggleButton-show');
      passwordInput.setAttribute('type', 'text');
    } else {
      toggle.classList.remove('Form_passwordToggleButton-show');
      passwordInput.setAttribute('type', 'password');
    }
  }


  static getFormControlFromInput(formInput) {
    const formControls = parents(formInput, '[data-form*="control"]');
    const formControl = formControls.shift();
    return formControl;
  }


  static createInputDisabledMutationObserver(formInput) {
    const formControl = Form.getFormControlFromInput(formInput);
    /**
     * Mutation Observer use to pick on up disabled attribute added to input and
     * reflect this on the formControl. Also runs check on init.
     */
    const formInputDisabledObserver = new MutationObserver((mutations) => {
      [...mutations].forEach(() => {
        Form.checkToDisable(formInput, formControl);
      });
    });
    formInputDisabledObserver.observe(formInput, {
      attributes: true,
      attributeFilter: ['disabled'],
    });

    return formInputDisabledObserver;
  }


  static createControlValidationMutationObserver(formControl) {
    /**
     * Mutation Observer is used here to check to see if FE validation has occurred
     * and inserted an error node. If so we update Form_control classList.
     * This type of validation is used in the booking list where error validation
     * is inserted.
     */
    const formControlValidationObserver = new MutationObserver((mutations) => {
      [...mutations].forEach((mutation) => {
        [...mutation.addedNodes].forEach((node) => {
          if (Form.checkForValidationError(node)) {
            formControl.classList.add('error');
          }
        });

        [...mutation.removedNodes].forEach((node) => {
          if (Form.checkForValidationError(node)) {
            formControl.classList.remove('error');
          }
        });
      });
    });
    formControlValidationObserver.observe(formControl, { childList: true });

    return formControlValidationObserver;
  }


  reset() {
    if (this.form) {
      this.form.classList.remove('validated');
    }
  }


  isValid() {
    // we need to ensure we iterate over all to execute checkValidity to ensure invalid events fire.
    return this.inputs.reduce((isFormValid, input) => {
      const isInputValid = input.checkValidity();
      if (__DEVELOPMENT__) {
        console.log('Form index.js isValid() input.checkValidity:', input.id, input.nodeName, isInputValid);
      }
      return (!isInputValid ? false : isFormValid);
    }, true);
  }


  initPasswordToggles() {
    this.passwordToggles = [...this.element.querySelectorAll('[data-form*="password-toggle-button"]')];
    this.passwordToggles.forEach((toggle) => {
      const passwordInput = toggle.parentNode.querySelector('[data-form*="password-input"]');
      if (__DEVELOPMENT__) {
        if (!passwordInput) {
          throw Error('Missing attribute [data-form="password-input"] from password input, or missing password input element');
        }
      }

      toggle.addEventListener('click', Form.onPasswordInputToggle);
    });
  }


  initHeavyHandedInputFocus() {
    this.inputs.forEach((formInput) => {
      const formControls = parents(formInput, '[data-form*="control"]');

      if (formControls.length === 0) {
        if (__DEVELOPMENT__) {
          throw Error('Missing attribute [data-form*="control"] from input, or missing input element');
        }
        return;
      }

      const formControl = formControls.shift();

      this.formInputDisabledObservers.set(
        formInput,
        Form.createInputDisabledMutationObserver(formInput),
      );

      Form.checkToDisable(formInput, formControl);

      this.formControlValidationObservers.set(
        formControl,
        Form.createControlValidationMutationObserver(formControl),
      );

      // We want to be able to detect an invalid control to set its focus border style
      // even if we have not altered its value.
      formInput.addEventListener('invalid', Form.onInputInvalid, true);

      formInput.addEventListener('focus', Form.onInputFocus);

      formInput.addEventListener('blur', Form.onInputBlur);

      formInput.addEventListener('input', Form.onInputChange);

      formInput.addEventListener('change', Form.onInputChange);
    });
  }


  static checkInputValue(formInput, formControl) {
    // @TODO check for radio group value
    if (formInput.value.trim() === '') {
      formControl.classList.remove('active');
    } else {
      formControl.classList.add('active');
    }
  }


  static checkForValidationError(node) {
    return node.dataset.form && node.dataset.form.indexOf('validation-error') > -1;
  }


  static checkToDisable(formInput, formControl) {
    if (formInput.disabled) {
      formControl.classList.add('disabled');
    } else {
      formControl.classList.remove('disabled');
    }
  }
}
