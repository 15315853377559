import InViewManager from '../../managers/in-view';

export default class WhatsOnSearchLoader {
  constructor(element) {
    WhatsOnSearchLoader.load(element);
  }

  static async load(element) {
    const { default: WhatsOnSearch } = await import(/* webpackChunkName: "WhatsOnSearch" */'../render/WhatsOnSearch');

    new WhatsOnSearch(element);
  }
}
