import Utils from './Utils';

export const MONTHS_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const MONTHS_LONG = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export function daysBetweenDates(startDate, endDate) {
  const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
}

export function formatFromPretty(dateString) {
  // expected format 03/12/2018
  const year = dateString.substr(6, 4);
  const month = dateString.substr(3, 2);
  const date = dateString.substr(0, 2);

  return `${year}-${month}-${date}`;
}

export function formatToPretty(dateObject, shortYear) {
  let year = dateObject.getFullYear();
  if (shortYear) {
    year = year.toString().substr(-2);
  }
  const month = Utils.pad(dateObject.getMonth() + 1, 2);
  const date = Utils.pad(dateObject.getDate(), 2);

  return `${date}/${month}/${year}`;
}

export function formatToPrettyMonthYear(dateObject) {
  const year = dateObject.getFullYear();
  const month = Utils.pad(dateObject.getMonth() + 1, 2);

  return `${month}/${year}`;
}

export function formatFromPrettyToAPI(dateString) {
  if (!dateString) return undefined;
  // expected format 03%2F29%2F2019%2000%3A00%3A00
  const year = dateString.substr(6, 4);
  const month = dateString.substr(3, 2);
  const date = dateString.substr(0, 2);

  return encodeURIComponent(`${month}/${date}/${year} 00:00:00`);
}

export function formatFromDateToWhatsOnAPI(dateObject) {
  if (!dateObject) return undefined;
  // expected output format 2020-12-31
  const year = dateObject.getFullYear();
  const month = Utils.pad(dateObject.getMonth() + 1, 2);
  const date = Utils.pad(dateObject.getDate(), 2);

  return `${year}-${month}-${date}`;
}

export function formatFromHistoryToDate(dateString) {
  if (!dateString) return undefined;
  // expected input format 12-31-2020
  const year = dateString.substr(6, 4);
  const month = dateString.substr(3, 2);
  const date = dateString.substr(0, 2);

  return new Date(`${year}-${month}-${date} 00:00:00`);
}

export function formatFromDateToHistory(dateObject) {
  if (!dateObject) return undefined;
  // expected output format 31-12-2020
  const year = dateObject.getFullYear();
  const month = Utils.pad(dateObject.getMonth() + 1, 2);
  const date = Utils.pad(dateObject.getDate(), 2);

  return `${date}-${month}-${year}`;
}

export function formatToUTCString(dateString, longMonth) {
  const dateObject = new Date(dateString);
  const timezoneOffsetHours = dateObject.getTimezoneOffset() / 60;
  const currentTimeUTCHours = dateObject.getUTCHours();
  dateObject.setUTCHours(currentTimeUTCHours + timezoneOffsetHours);

  const monthNumber = dateObject.getUTCMonth();

  let month = MONTHS_SHORT[monthNumber];
  if (longMonth) month = MONTHS_LONG[monthNumber];
  const day = dateObject.getUTCDate();
  const year = dateObject.getUTCFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
}

export function formatToString(dateString, longMonth) {
  const dateObject = new Date(dateString);

  const monthNumber = dateObject.getMonth();

  let month = MONTHS_SHORT[monthNumber];
  if (longMonth) month = MONTHS_LONG[monthNumber];
  const day = dateObject.getDate();
  const year = dateObject.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
}


export function addDays(days, dateObject = new Date()) {
  const date = new Date(dateObject.getTime());
  date.setDate(date.getDate() + days);
  return date;
}


export function addMonths(months, dateObject = new Date()) {
  const date = new Date(dateObject.getTime());
  date.setMonth(date.getMonth() + months);
  return date;
}


export function isValidDateObject(dateObject) {
  if (
    Object.prototype.toString.call(dateObject) === '[object Date]'
    && !Number.isNaN(dateObject.getTime())
  ) {
    return true;
  }

  return false;
}

export function manageDateObject(dateString) {
  const dateComponents = dateString.split('/');
  const day = dateComponents[0];
  const month = dateComponents[1];
  const year = dateComponents[2];
  const newDateString = `${year}/${month}/${day}`;
  const date = new Date(newDateString);

  // Add zero to single figure days to match day string passed in.
  // new Date() works by fast-fowarding an invalid date to the next
  // valid day - therefore, if the day number changes we know that
  // date passed in originally was not valid, therefore return false

  // bespoke validation to handle years that Date() converts to 2000, 2001, 2003 & 2003
  const yearIsValid = year !== '0000' && year !== '0001' && year !== '0002' && year !== '0003';

  if (((date.getDate() < 10 ? '0' : '') + date.getDate() === day) && yearIsValid) {
    return date;
  }

  return false;
}
